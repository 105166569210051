//Import components
import * as GrIcons from 'react-icons/gr'
import { useState } from 'react'
import { NotificationsFilterMenu } from './NotificationsFilterMenu/NotificationsFilterMenu'

//Import styles
import '../../../assets/styles/components/notifications/notificationsMenu/notificationsWindowControls.css'

export const NotificationsWindowControls = ({
  isRefreshListByFiltersRequested,
  isGetRefreshListDataByFilterRequested,
  setIsRefreshListByFiltersRequested,
  setIsGetRefreshListDataByFilterRequested,
  setIsDatePickerOpened,
  setIsFilterValueSelected,
}) => {
  const [isOpenedNotificationsFilter, setIsOpenedNotificationsFilter] = useState(false)

  function triggerHandleClickNotificationsFilter() {
    setIsOpenedNotificationsFilter(!isOpenedNotificationsFilter)
  }

  return (
    <>
      <div className="notifications-window-controls">
        <div className="notifications-filter-button" onClick={() => triggerHandleClickNotificationsFilter()}>
          <h3 className="notifications-filter-button-name">Notifications filter</h3>
          <h3 className="notifications-filter-button-icon">{isOpenedNotificationsFilter ? <GrIcons.GrLinkUp /> : <GrIcons.GrLinkDown />}</h3>
        </div>
      </div>
      {isOpenedNotificationsFilter && (
        <NotificationsFilterMenu
          isRefreshListByFiltersRequested={isRefreshListByFiltersRequested}
          isGetRefreshListDataByFilterRequested={isGetRefreshListDataByFilterRequested}
          setIsRefreshListByFiltersRequested={setIsRefreshListByFiltersRequested}
          setIsGetRefreshListDataByFilterRequested={setIsGetRefreshListDataByFilterRequested}
          setIsDatePickerOpened={setIsDatePickerOpened}
          setIsFilterValueSelected={setIsFilterValueSelected}
        />
      )}
    </>
  )
}
