//Import components
import VehiclesMap from '../components/VehiclesMap/VehiclesMap'
import StatisticsMenu from '../components/StatisticsMenu/StatisticsMenu'
import Navbar from '../components/Navbar'
import { useState } from 'react'

export default function Home() {
  const [mapRefData, setMapRefData] = useState()
  const [lastMapPopupVehiclesMarkersElementData, setLastMapPopupVehiclesMarkersElementData] = useState()
  const [lastMapVehiclesMarkersData, setLastMapVehiclesMarkersData] = useState()
  const [forceRerenderByMarkerUpdate, setForceRerenderByMarkerUpdate] = useState()
  const [searchMapMarkersData, setSearchMapMarkersData] = useState()

  return (
    <>
      <Navbar
        mapRefData={mapRefData}
        lastMapPopupVehiclesMarkersElementData={lastMapPopupVehiclesMarkersElementData}
        searchMapMarkersData={searchMapMarkersData}
        forceRerenderByMarkerUpdate={forceRerenderByMarkerUpdate}
      />
      <VehiclesMap
        setMapRefData={setMapRefData}
        setLastMapPopupVehiclesMarkersElementData={setLastMapPopupVehiclesMarkersElementData}
        setLastMapVehiclesMarkersData={setLastMapVehiclesMarkersData}
        setForceRerenderByMarkerUpdate={setForceRerenderByMarkerUpdate}
      />
      <StatisticsMenu
        mapRefData={mapRefData}
        lastMapVehiclesMarkersData={lastMapVehiclesMarkersData}
        forceRerenderByMarkerUpdate={forceRerenderByMarkerUpdate}
        setSearchMapMarkersData={setSearchMapMarkersData}
      />
    </>
  )
}
