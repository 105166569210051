import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const userLogin = createAsyncThunk('user/login', async ({ login, password }, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const response = await axios.post(`${process.env.REACT_APP_MAP_SERVER_API_URL}/users/login`, { login, password }, config)

    return response.data.user
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const userReLogin = createAsyncThunk('user/reLogin', async (_, { getState, rejectWithValue }) => {
  const { userId, userToken: token } = getState().auth

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }

    const response = await axios.post(`${process.env.REACT_APP_MAP_SERVER_API_URL}/users/checkAuthToken/${userId}`, {}, config)

    return response.data.payload.user
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue({ data: error.response.data, status: error.response.status })
    } else {
      return rejectWithValue(error.message)
    }
  }
})
