//Import components
import { DateRangePicker } from 'rsuite'
import { useDispatch } from 'react-redux'
import { setDateFilterValue } from '../../../../store/slices/vehicleNotifications/vehicleNotificationsSlice'
import { addDays, subDays } from 'date-fns'

//Import styles
import 'rsuite/dist/rsuite-no-reset.min.css'
import '../../../../assets/styles/components/notifications/notificationsMenu/notificationsFilterMenu/datePicker.css'

export const DatePickerBar = ({ dateInputValue, setDateInputValue, setIsDatePickerOpened, setIsFilterValueSelected }) => {
  const dispatch = useDispatch()

  const handleDateChange = value => {
    const startDateValue = value?.[0] ? new Date(value?.[0]).toISOString() : null
    const endDateValue = value?.[1] ? new Date(value?.[1]).toISOString() : null
    const dateValue = startDateValue && endDateValue ? [startDateValue, endDateValue] : []
    setDateInputValue(value)
    dispatch(setDateFilterValue(dateValue))
  }

  const startDate = new Date(new Date().setUTCHours(0, 0, 0, 0))
  const endDate = new Date(new Date().setUTCHours(23, 59, 59, 999))

  const Ranges = [
    {
      label: 'today',
      value: [startDate, endDate],
    },
    {
      label: 'yesterday',
      value: [addDays(startDate, -1), addDays(endDate, -1)],
    },
    {
      label: 'last7Days',
      value: [subDays(startDate, 6), endDate],
    },
  ]

  return (
    <>
      <DateRangePicker
        value={dateInputValue}
        onOk={date => {
          handleDateChange(date)
          setIsFilterValueSelected(false)
        }}
        onClean={() => {
          handleDateChange([])
        }}
        onShortcutClick={shortcut => {
          handleDateChange(shortcut.value)
        }}
        onOpen={() => {
          setIsDatePickerOpened(true)
        }}
        onClose={() => {
          setIsDatePickerOpened(false)
        }}
        showOneCalendar
        placeholder="Date range"
        format="dd.MM.yyyy"
        character=" – "
        ranges={Ranges}
      />
    </>
  )
}
