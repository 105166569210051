//Import components
import { useCallback, useEffect, useRef, useState } from 'react'
import * as GrIcons from 'react-icons/gr'
import chooseStatisticsMenuMarkerImage from './actions/chooseStatisticsMenuMarkerImage'

//Import styles
import '../../assets/styles/components/statisticsMenu.css'

// StatisticsMenu
export default function StatisticsMenu({ mapRefData, lastMapVehiclesMarkersData, forceRerenderByMarkerUpdate, setSearchMapMarkersData }) {
  const menuRef = useRef()
  const [isOpenMenuButtonExpanded, setIsOpenMenuButtonExpanded] = useState(false)
  const [isOpenStatisticsMenu, setIsOpenStatisticsMenu] = useState(false)
  const [pressedListItems, setPressedListItems] = useState({
    Available: false,
    'In use': false,
    Discharged: false,
    Charging: false,
    'Needs investigation': false,
    Transportation: false,
    'Not ready': false,
    Stolen: false,
    Reserved: false,
    'Battery < 4%': false,
    Maintenance: false,
    Storage: false,
  })
  const [isClearedPressedListItemsButtonPressed, setIsClearedPressedListItemsButtonPressed] = useState(false)

  // Update Markers image when status inside statistics menu is selected
  useEffect(() => {
    // Update search Markers data
    setSearchMapMarkersData(lastMapVehiclesMarkersData)

    // Check if list items are not selected
    let listItemsAreNotSelected = Object.values(pressedListItems).every(value => value === false)

    // Check if lastMapVehiclesMarkersData are not empty
    if (lastMapVehiclesMarkersData && Object.keys(lastMapVehiclesMarkersData)?.length > 0 && !listItemsAreNotSelected) {
      // Find keys where the value is true
      const pressedItems = Object.entries(pressedListItems)
        .filter(([_, value]) => value === true)
        .map(([key, _]) => key)

      // Find matching Markers
      const matchingMarkers = Object.entries(lastMapVehiclesMarkersData)
        .filter(
          ([
            _,
            {
              properties: { custom_vehicle_status },
            },
          ]) => pressedItems.includes(custom_vehicle_status)
        )
        .map(([markerId, { properties }]) => ({ markerId, properties }))

      // Filter map Markers (layer features)
      if (mapRefData && Object.entries(lastMapVehiclesMarkersData)?.length > 0 && mapRefData.getSource('vehiclesSource')) {
        const filter = ['any', ...pressedItems.map(item => ['==', ['get', 'custom_vehicle_status'], item])]
        mapRefData.setFilter('vehiclesLayer', filter)
        mapRefData.setPaintProperty('vehiclesLayer', 'icon-opacity', 1) // Set opacity to 1 for all Markers
      }

      // Convert matching Markers array to object format, in order to update state
      const matchingMarkersObject = matchingMarkers.reduce((acc, { markerId, properties }) => {
        acc[markerId] = { properties }
        return acc
      }, {})

      // Update search selected Markers data
      setSearchMapMarkersData(matchingMarkersObject)
    }
  }, [pressedListItems, isOpenStatisticsMenu, forceRerenderByMarkerUpdate, mapRefData, lastMapVehiclesMarkersData, setSearchMapMarkersData])

  // Clear pressed list items
  useEffect(() => {
    // Check if list items are not selected
    let listItemsAreNotSelected = Object.values(pressedListItems).every(value => value === false)

    // Check if lastMapVehiclesMarkersData are not empty and there are not selected list items
    if (
      mapRefData &&
      mapRefData.getSource('vehiclesSource') &&
      lastMapVehiclesMarkersData &&
      Object.keys(lastMapVehiclesMarkersData)?.length > 0 &&
      listItemsAreNotSelected
    ) {
      mapRefData.setFilter('vehiclesLayer', null)
      mapRefData.setPaintProperty('vehiclesLayer', 'icon-opacity', ['case', ['get', 'isMarkerActive'], 1, 0.1]) // Revert opacity based on isMarkerActive property
    }
  }, [isClearedPressedListItemsButtonPressed, pressedListItems, mapRefData, lastMapVehiclesMarkersData])

  function menuTriggerHandleClickStatisticsMenu(closeButtonPressed = false) {
    setIsOpenStatisticsMenu(!isOpenStatisticsMenu)
    setIsOpenMenuButtonExpanded(!isOpenMenuButtonExpanded)
  }

  function menuTriggerHandleClickClearSelectedItemsList() {
    setIsClearedPressedListItemsButtonPressed(!isClearedPressedListItemsButtonPressed)
    Object.entries(pressedListItems).map(([status]) => (pressedListItems[status] = false))
  }

  return (
    <div className="statistics-menu-container" ref={menuRef}>
      <div
        className={`statistics-menu-trigger ${isOpenMenuButtonExpanded ? 'expanded' : 'shrunken'}`}
        onClick={() => menuTriggerHandleClickStatisticsMenu()}
      >
        <h3 className="statistics-menu-trigger-name">{isOpenMenuButtonExpanded ? 'Statistics' : ''} </h3>
        <h3 className={`statistics-menu-trigger-icon ${isOpenMenuButtonExpanded ? 'expanded' : 'shrunken'}`}>
          {isOpenMenuButtonExpanded ? <GrIcons.GrLinkUp /> : <GrIcons.GrLinkNext />}
        </h3>
      </div>

      <div className={`dropdown-statistics-menu ${isOpenStatisticsMenu ? 'active' : 'inactive'}`}>
        <div className="opened-statistics-menu-name-container">
          <h3 className="opened-statistics-menu-name-clear-icon" onClick={() => menuTriggerHandleClickClearSelectedItemsList()}>
            <GrIcons.GrClearOption />
          </h3>
          <h3 className="opened-statistics-menu-name">Statistics</h3>
          <h3 className="opened-statistics-menu-close-icon" onClick={() => menuTriggerHandleClickStatisticsMenu(true)}>
            <GrIcons.GrLinkDown />
          </h3>
        </div>
        <div className="drop-down-list-container">
          <DropdownList
            pressedListItems={pressedListItems}
            setPressedListItems={setPressedListItems}
            lastMapVehiclesMarkersData={lastMapVehiclesMarkersData}
            forceRerenderByMarkerUpdate={forceRerenderByMarkerUpdate}
            isOpenStatisticsMenu={isOpenStatisticsMenu}
          />{' '}
        </div>
      </div>
    </div>
  )
}

function DropdownList({ pressedListItems, setPressedListItems, lastMapVehiclesMarkersData, forceRerenderByMarkerUpdate, isOpenStatisticsMenu }) {
  function handleListItemClick(status) {
    setPressedListItems(prevState => ({
      ...prevState,
      [status]: !prevState[status],
    }))
  }

  const updateMarkersCount = useCallback(() => {
    const vehicleStatusCounts = {
      Available: 0,
      'In use': 0,
      Discharged: 0,
      Charging: 0,
      'Needs investigation': 0,
      Transportation: 0,
      'Not ready': 0,
      Stolen: 0,
      Reserved: 0,
      'Battery < 4%': 0,
      Maintenance: 0,
      Storage: 0,
    }

    if (lastMapVehiclesMarkersData) {
      Object.entries(lastMapVehiclesMarkersData).forEach(([_, { properties }]) => {
        if (!vehicleStatusCounts.hasOwnProperty(properties?.custom_vehicle_status)) {
          vehicleStatusCounts[properties?.custom_vehicle_status] = 0
        }
        vehicleStatusCounts[properties?.custom_vehicle_status] += 1
      })
    }
    return vehicleStatusCounts
  }, [lastMapVehiclesMarkersData])

  useEffect(() => {
    if (isOpenStatisticsMenu && lastMapVehiclesMarkersData) {
      updateMarkersCount()
    }
  }, [forceRerenderByMarkerUpdate, isOpenStatisticsMenu, lastMapVehiclesMarkersData, updateMarkersCount])

  const vehicleStatusCounts = updateMarkersCount()
  if (!vehicleStatusCounts) {
    return null
  }

  // Create an array of React elements based on vehicleStatusCounts
  const statusElements = Object.entries(vehicleStatusCounts).map(([status, count]) => {
    return (
      <li
        className={`drop-down-list-item ${pressedListItems[status] ? 'active' : 'inactive'}`}
        key={status}
        onClick={() => handleListItemClick(status)}
      >
        <div
          className="image-status-container"
          style={{
            backgroundImage: (() => chooseStatisticsMenuMarkerImage(status))(),
          }}
        />

        <div className="status-container">{status}</div>
        <div className="count-container">{count}</div>
      </li>
    )
  })

  // Render the list of elements as a React component
  return <ul className="drop-down-list">{statusElements}</ul>
}
