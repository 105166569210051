import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getLocationsData = createAsyncThunk('locations/getLocationsData', async (_, { getState, rejectWithValue }) => {
  const { userId, userToken: token } = getState().auth

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }

    const response = await axios.get(`${process.env.REACT_APP_MAP_SERVER_API_URL}/locations/getLocations/${userId}`, config)

    const responseData = response.data.payload
    return responseData.locations
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue({ data: error.response.data, status: error.response.status })
    } else {
      return rejectWithValue(error.message)
    }
  }
})
