// Intended to add geofencing zones on the map (source and layer)
export default function setGeofencingZonesLayer(map, geofencingZonesData, layerName) {
  switch (layerName) {
    case 'grayAreaLayer':
      /* Gray area */
      // Gray area source
      map.addSource('grayAreaSource', {
        type: 'geojson',
        data: geofencingZonesData.grayArea,
      })

      // Gray area map layers
      map.addLayer({
        id: 'grayAreaLayer',
        type: 'fill',
        source: 'grayAreaSource',
        layout: {},
        paint: {
          'fill-color': '#000000',
          'fill-opacity': 0.2,
        },
        filter: ['==', '$type', 'Polygon'],
      })

      // Gray area outline around the polygon.
      map.addLayer({
        id: 'grayAreaOutlineLayer',
        type: 'line',
        source: 'grayAreaSource',
        layout: {},
        paint: {
          'line-color': '#000000',
          'line-width': 2,
        },
        filter: ['==', '$type', 'Polygon'],
      })
      break
    case 'parkingZonesLayer':
      /* Parking zone */
      // Parking zones source
      map.addSource('parkingZonesSource', {
        type: 'geojson',
        data: geofencingZonesData.parkingZones,
      })

      // Parking zones map layers
      map.addLayer({
        id: 'parkingZonesLayer',
        type: 'fill',
        source: 'parkingZonesSource',
        layout: {},
        paint: {
          'fill-color': '#21C378',
          'fill-opacity': 0.15,
        },
        filter: ['==', '$type', 'Polygon'],
      })

      // Parking zones outline around the polygon.
      map.addLayer({
        id: 'parkingZonesOutlineLayer',
        type: 'line',
        source: 'parkingZonesSource',
        layout: {},
        paint: {
          'line-color': '#21C378',
          'line-width': 2,
        },
        filter: ['==', '$type', 'Polygon'],
      })
      break

    case 'noParkingZonesLayer':
      /* No parking zone */
      // No parking zones source
      map.addSource('noParkingZonesSource', {
        type: 'geojson',
        data: geofencingZonesData.noParkingZones,
      })

      // No go zones map layers
      map.addLayer({
        id: 'noParkingZonesLayer',
        type: 'fill',
        source: 'noParkingZonesSource',
        layout: {},
        paint: {
          'fill-color': '#FB4747',
          'fill-opacity': 0.15,
        },
        filter: ['==', '$type', 'Polygon'],
      })

      // No go zones outline around the polygon.
      map.addLayer({
        id: 'noParkingZonesOutlineLayer',
        type: 'line',
        source: 'noParkingZonesSource',
        layout: {},
        paint: {
          'line-color': '#FB4747',
          'line-width': 2,
        },
        filter: ['==', '$type', 'Polygon'],
      })
      break

    case 'speedLimitZonesLayer':
      /* Speed limit zone */
      // Speed limit zones source
      map.addSource('speedLimitZonesSource', {
        type: 'geojson',
        data: geofencingZonesData.speedLimitZones,
      })

      // Speed limit zones map layers
      map.addLayer({
        id: 'speedLimitZonesLayer',
        type: 'fill',
        source: 'speedLimitZonesSource',
        layout: {},
        paint: {
          'fill-color': '#5C22C9',
          'fill-opacity': 0.15,
        },
        filter: ['==', '$type', 'Polygon'],
      })

      // Speed limit zones outline around the polygon.
      map.addLayer({
        id: 'speedLimitZonesOutlineLayer',
        type: 'line',
        source: 'speedLimitZonesSource',
        layout: {},
        paint: {
          'line-color': '#5C22C9',
          'line-width': 2,
        },
        filter: ['==', '$type', 'Polygon'],
      })
      break

    default:
      break
  }
}
