import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  geofencingZonesData: null,
  isObtainZonesDataFailed: false,
}

const geofencingZonesSlice = createSlice({
  name: 'geofencingZones',
  initialState,
  reducers: {},
})
//export const { updateGeofencingZonesData } = geofencingZonesSlice.actions

export default geofencingZonesSlice.reducer
