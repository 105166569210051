import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  markersImagesData: {},
}

const mapMarkersSlice = createSlice({
  name: 'mapMarkers',
  initialState,
  reducers: {
    setMarkersImagesData: (state, action) => {
      state.markersImagesData = { ...action.payload }
    },
    setSearchMarkersData: (state, action) => {
      state.searchMarkersData = { ...action.payload }
    },
  },
})

export const { setMarkersImagesData, setSearchMarkersData } = mapMarkersSlice.actions

export default mapMarkersSlice.reducer
