//Import components
import { NotificationTypeSearchResult } from './SearchResult'

//Import styles
import '../../../../../assets/styles/components/notifications/notificationsMenu/notificationsFilterMenu/notificationTypeSearch/searchResultList.css'

export const NotificationTypeSearchResultsList = ({ result, typeInputValue, setTypeInputValue, setIsClosedDropdown, setIsFilterValueSelected }) => {
  return (
    <div className="notification-type-search-results-list">
      {result?.length > 0
        ? result.map((result, id) => {
            return (
              <NotificationTypeSearchResult
                result={result}
                key={id}
                typeInputValue={typeInputValue}
                setTypeInputValue={setTypeInputValue}
                setIsClosedDropdown={setIsClosedDropdown}
                setIsFilterValueSelected={setIsFilterValueSelected}
              />
            )
          })
        : null}
      {!result?.length ? <p className="notification-type-search-data-error">No such data</p> : null}
    </div>
  )
}
