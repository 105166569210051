//Import components
import { createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom'
import RequireAuth from './components/RequireAuth'
import Layout from './components/Layout'
import Login from './pages/Login'
import Home from './pages/Home'
import Error from './pages/Error'
import CheckUserIsLogged from './components/CheckUserIsLogged'

// Router singleton
const router = createBrowserRouter([
  {
    path: '/*',
    element: <Root />,
  },
])

export default function App() {
  return <RouterProvider router={router} />
}

function Root() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="*" element={<Error />} />
        <Route element={<CheckUserIsLogged />}>{<Route index element={<Login />} />}</Route>
        <Route element={<CheckUserIsLogged />}>{<Route path="login" element={<Login />} />}</Route>

        {/* protected routes */}
        <Route element={<RequireAuth />}>{<Route path="home" element={<Home />} />}</Route>
      </Route>
    </Routes>
  )
}
