import { createSlice } from '@reduxjs/toolkit'
import { getVehicleNotificationsData } from './vehicleNotificationsAction.js'

// Initialize from local storage
const isMuteVehicleNotifications = localStorage.getItem('isMuteVehicleNotifications') ? localStorage.getItem('isMuteVehicleNotifications') : false

const initialState = {
  loading: false,
  error: null,
  success: false,
  vehicleNotificationsData: null,
  vehicleNotificationsCount: null,
  appendedNotificationsList: [],
  isMuteVehicleNotifications,
  dateFilterValue: [],
  notificationTypeFilterValue: '',
  vehicleIdFilterValue: '',
}

const vehicleNotificationsSlice = createSlice({
  name: 'vehicleNotifications',
  initialState,
  reducers: {
    setIsMuteVehicleNotifications: (state, action) => {
      localStorage.setItem('isMuteVehicleNotifications', action.payload) // store in local storage
      state.isMuteVehicleNotifications = action.payload
    },
    resetAppendedNotificationsList: (state, action) => {
      state.appendedNotificationsList = []
    },
    appendNotifications: (state, action) => {
      if (action.payload?.length > 0) {
        // const newNotifications = action.payload.filter(
        //   newNotification => !state.appendedNotificationsList.some(existingNotification => existingNotification._id === newNotification._id)
        // )
        // state.appendedNotificationsList = [...state.appendedNotificationsList, ...newNotifications]
        state.appendedNotificationsList = [...state.appendedNotificationsList, ...action.payload]
      }
    },
    prependNotifications: (state, action) => {
      if (action.payload) {
        state.appendedNotificationsList = [action.payload, ...state.appendedNotificationsList]
      }
    },
    setDateFilterValue: (state, action) => {
      if (action.payload) {
        state.dateFilterValue = action.payload
      }
    },
    setNotificationTypeFilterValue: (state, action) => {
      if (action.payload || action.payload === '') {
        state.notificationTypeFilterValue = action.payload
      }
    },
    setVehicleIdFilterValue: (state, action) => {
      if (action.payload || action.payload === '') {
        state.vehicleIdFilterValue = action.payload
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getVehicleNotificationsData.pending, state => {
        state.vehicleNotificationsData = []
        state.vehicleNotificationsCount = null
        state.loading = true
        state.error = null
      })
      .addCase(getVehicleNotificationsData.fulfilled, (state, action) => {
        state.loading = false
        state.vehicleNotificationsData = action.payload.records
        state.vehicleNotificationsCount = action.payload.recordsCount
        state.success = true
      })
      .addCase(getVehicleNotificationsData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.success = false
      })
  },
})

export const {
  setIsMuteVehicleNotifications,
  setDateFilterValue,
  setNotificationTypeFilterValue,
  setVehicleIdFilterValue,
  resetAppendedNotificationsList,
  appendNotifications,
  prependNotifications,
} = vehicleNotificationsSlice.actions

export default vehicleNotificationsSlice.reducer
