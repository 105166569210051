import { createSlice } from '@reduxjs/toolkit'
import { getVehiclesIdsData } from './vehiclesAction'

const initialState = {
  loading: false,
  error: null,
  success: false,
  vehiclesIdsData: null,
  vehiclesIdsCount: 0,
}

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVehiclesIdsData.pending, state => {
        state.vehiclesIdsData = []
        state.vehiclesIdsCount = null
        state.loading = true
        state.error = null
      })
      .addCase(getVehiclesIdsData.fulfilled, (state, action) => {
        state.loading = false
        state.vehiclesIdsData = action.payload.records
        state.vehiclesIdsCount = action.payload.recordsCount
        state.success = true
      })
      .addCase(getVehiclesIdsData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.success = false
      })
  },
})

//export const { updateVehiclesData } = vehiclesSlice.actions

export default vehiclesSlice.reducer
