//Import components
import { VehicleIdSearchResult } from './SearchResult'

//Import styles
import '../../../../../assets/styles/components/notifications/notificationsMenu/notificationsFilterMenu/vehicleIdSearch/searchResultList.css'

export const VehicleIdSearchResultsList = ({
  result,
  vehicleIdInputValue,
  setVehicleIdInputValue,
  setIsClosedDropdown,
  isLoadingVehiclesIdsData,
  getVehiclesIdsDataError,
  setIsFilterValueSelected,
}) => {
  return (
    <div className="vehicle-id-search-results-list">
      {result?.length > 0
        ? result.map((result, id) => {
            return (
              <VehicleIdSearchResult
                result={result}
                key={id}
                vehicleIdInputValue={vehicleIdInputValue}
                setVehicleIdInputValue={setVehicleIdInputValue}
                setIsClosedDropdown={setIsClosedDropdown}
                setIsFilterValueSelected={setIsFilterValueSelected}
              />
            )
          })
        : null}
      {getVehiclesIdsDataError ? <p className="get-vehicle-ids-data-error">Error: {getVehiclesIdsDataError}</p> : null}
      {!result?.length && isLoadingVehiclesIdsData ? (
        <div className="get-vehicle-ids-data-spinner-container">
          <div className="get-vehicle-ids-data-loading-spinner" />
        </div>
      ) : null}

      {!result?.length && !isLoadingVehiclesIdsData ? <p className="get-vehicle-ids-data-error">No such data</p> : null}
    </div>
  )
}
