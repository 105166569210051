import alertVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Alert.svg'
import availableVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Available.svg'
import inUseVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/InUse.svg'
import reservedVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Reserved.svg'
import dischargedVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Discharged.svg'
import chargingVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Charging.svg'
import needsInvestigationVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/NeedsInvestigation.svg'
import stolenVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Stolen.svg'
import notReadyVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/NotReady.svg'
import transportationVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Transportation.svg'
import maintenanceVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Maintenance.svg'
import storageVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Storage.svg'
import alertVehicleMarkerArrow from '../../../assets/images/mapImages/Markers/scootersArrow/Alert.svg'
import availableVehicleMarkerArrow from '../../../assets/images/mapImages/Markers/scootersArrow/Available.svg'
import inUseVehicleMarkerArrow from '../../../assets/images/mapImages/Markers/scootersArrow/InUse.svg'
import reservedVehicleMarkerArrow from '../../../assets/images/mapImages/Markers/scootersArrow/Reserved.svg'
import dischargedVehicleMarkerArrow from '../../../assets/images/mapImages/Markers/scootersArrow/Discharged.svg'
import chargingVehicleMarkerArrow from '../../../assets/images/mapImages/Markers/scootersArrow/Charging.svg'
import needsInvestigationVehicleMarkerArrow from '../../../assets/images/mapImages/Markers/scootersArrow/NeedsInvestigation.svg'
import stolenVehicleMarkerArrow from '../../../assets/images/mapImages/Markers/scootersArrow/Stolen.svg'
import notReadyVehicleMarkerArrow from '../../../assets/images/mapImages/Markers/scootersArrow/NotReady.svg'
import transportationVehicleMarkerArrow from '../../../assets/images/mapImages/Markers/scootersArrow/Transportation.svg'
import maintenanceVehicleMarkerArrow from '../../../assets/images/mapImages/Markers/scootersArrow/Maintenance.svg'
import storageVehicleMarkerArrow from '../../../assets/images/mapImages/Markers/scootersArrow/Storage.svg'
import alertVehicleMarkerCar from '../../../assets/images/mapImages/Markers/cars/Alert.svg'
import availableVehicleMarkerCar from '../../../assets/images/mapImages/Markers/cars/Available.svg'
import inUseVehicleMarkerCar from '../../../assets/images/mapImages/Markers/cars/InUse.svg'
import reservedVehicleMarkerCar from '../../../assets/images/mapImages/Markers/cars/Reserved.svg'
import dischargedVehicleMarkerCar from '../../../assets/images/mapImages/Markers/cars/Discharged.svg'
import chargingVehicleMarkerCar from '../../../assets/images/mapImages/Markers/cars/Charging.svg'
import needsInvestigationVehicleMarkerCar from '../../../assets/images/mapImages/Markers/cars/NeedsInvestigation.svg'
import stolenVehicleMarkerCar from '../../../assets/images/mapImages/Markers/cars/Stolen.svg'
import notReadyVehicleMarkerCar from '../../../assets/images/mapImages/Markers/cars/NotReady.svg'
import transportationVehicleMarkerCar from '../../../assets/images/mapImages/Markers/cars/Transportation.svg'
import maintenanceVehicleMarkerCar from '../../../assets/images/mapImages/Markers/cars/Maintenance.svg'
import storageVehicleMarkerCar from '../../../assets/images/mapImages/Markers/cars/Storage.svg'

const markerCircleImages = [
  { name: 'Circle Battery < 4%', url: alertVehicleMarkerCircle },
  { name: 'Circle Available', url: availableVehicleMarkerCircle },
  { name: 'Circle In use', url: inUseVehicleMarkerCircle },
  { name: 'Circle Reserved', url: reservedVehicleMarkerCircle },
  { name: 'Circle Discharged', url: dischargedVehicleMarkerCircle },
  { name: 'Circle Charging', url: chargingVehicleMarkerCircle },
  { name: 'Circle Needs investigation', url: needsInvestigationVehicleMarkerCircle },
  { name: 'Circle Stolen', url: stolenVehicleMarkerCircle },
  { name: 'Circle Not ready', url: notReadyVehicleMarkerCircle },
  { name: 'Circle Transportation', url: transportationVehicleMarkerCircle },
  { name: 'Circle Maintenance', url: maintenanceVehicleMarkerCircle },
  { name: 'Circle Storage', url: storageVehicleMarkerCircle },
]

const markerArrowImages = [
  { name: 'Arrow Battery < 4%', url: alertVehicleMarkerArrow },
  { name: 'Arrow Available', url: availableVehicleMarkerArrow },
  { name: 'Arrow In use', url: inUseVehicleMarkerArrow },
  { name: 'Arrow Reserved', url: reservedVehicleMarkerArrow },
  { name: 'Arrow Discharged', url: dischargedVehicleMarkerArrow },
  { name: 'Arrow Charging', url: chargingVehicleMarkerArrow },
  { name: 'Arrow Needs investigation', url: needsInvestigationVehicleMarkerArrow },
  { name: 'Arrow Stolen', url: stolenVehicleMarkerArrow },
  { name: 'Arrow Not ready', url: notReadyVehicleMarkerArrow },
  { name: 'Arrow Transportation', url: transportationVehicleMarkerArrow },
  { name: 'Arrow Maintenance', url: maintenanceVehicleMarkerArrow },
  { name: 'Arrow Storage', url: storageVehicleMarkerArrow },
]

const markerCarImages = [
  { name: 'Car Battery < 4%', url: alertVehicleMarkerCar },
  { name: 'Car Available', url: availableVehicleMarkerCar },
  { name: 'Car In use', url: inUseVehicleMarkerCar },
  { name: 'Car Reserved', url: reservedVehicleMarkerCar },
  { name: 'Car Discharged', url: dischargedVehicleMarkerCar },
  { name: 'Car Charging', url: chargingVehicleMarkerCar },
  { name: 'Car Needs investigation', url: needsInvestigationVehicleMarkerCar },
  { name: 'Car Stolen', url: stolenVehicleMarkerCar },
  { name: 'Car Not ready', url: notReadyVehicleMarkerCar },
  { name: 'Car Transportation', url: transportationVehicleMarkerCar },
  { name: 'Car Maintenance', url: maintenanceVehicleMarkerCar },
  { name: 'Car Storage', url: storageVehicleMarkerCar },
]

function setVehicleImages(map, markerImages, width = 19, height = 19) {
  markerImages.forEach(image => {
    if (!map.hasImage(image.name)) {
      const customIcon = new Image(width, height)
      customIcon.onload = () => {
        map.addImage(image.name, customIcon)
      }
      customIcon.src = image.url // Ensure you use 'url' if it contains the path to the SVG
    }
  })
}

// Intended to add vehicles on the map (source and layer)
export default function setVehiclesLayer(map, vehiclesData) {
  showVehicleMoveDetectionCorrectionCircle(map) // Only for testing
  showVehicleActivityDetectionCircle(map) // Only for testing

  setVehicleImages(map, markerCircleImages, 19, 19)
  setVehicleImages(map, markerArrowImages, 24, 27)
  setVehicleImages(map, markerCarImages, 17, 31)

  // Add vehicles source
  map.addSource('vehiclesSource', {
    type: 'geojson',
    data: vehiclesData,
    cluster: false,
  })

  map.addLayer({
    id: 'vehiclesLayer',
    type: 'symbol',
    source: 'vehiclesSource',
    layout: {
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
      'symbol-sort-key': ['case', ['get', 'isMarkerActive'], 1, 0],
      'symbol-z-order': 'source',
      'icon-size': 0.9,
      'icon-image': [
        'case',
        ['==', ['get', 'vehicle_type'], 'CAR'], // If vehicle_type is 'CAR'
        ['concat', 'Car ', ['get', 'custom_vehicle_status']],
        ['==', ['get', 'isMarkerArrow'], true], // If isMarkerArrow is true
        ['concat', 'Arrow ', ['get', 'custom_vehicle_status']],
        // Default case
        ['concat', 'Circle ', ['get', 'custom_vehicle_status']],
      ],
      'icon-rotation-alignment': 'map',
      'icon-rotate': ['get', 'mapMarkerBearing'],
    },
    paint: {
      'icon-opacity': ['case', ['get', 'isMarkerActive'], 1, 0.1],
    },
  })
}

// Show vehicles move detection circles (only for testing)
function showVehicleMoveDetectionCorrectionCircle(map) {
  const vehicleCircles = {
    type: 'FeatureCollection',
    features: [],
  }

  // Add vehicle circles source
  map.addSource('vehicleMoveDetectionCirclesSource', {
    type: 'geojson',
    data: vehicleCircles,
  })

  map.addLayer({
    id: 'vehicleMoveDetectionCirclesLayer',
    type: 'fill',
    source: 'vehicleMoveDetectionCirclesSource',
    layout: {},
    paint: {
      'fill-color': '#17D751',
      'fill-opacity': ['case', ['get', 'isMarkerActive'], 0.5, 0],
    },
  })

  map.addLayer({
    id: 'vehicleMoveDetectionCirclesOutline',
    type: 'line',
    source: 'vehicleMoveDetectionCirclesSource',
    layout: {},
    paint: {
      'line-color': '#17d751',
      'line-width': 0.2,
    },
  })
}

// Show vehicles activity detection circles, when exit circle marker became active (visible on map) (only for testing)
function showVehicleActivityDetectionCircle(map) {
  const vehicleCircles = {
    type: 'FeatureCollection',
    features: [],
  }

  // Add vehicle circles source
  map.addSource('vehicleActivityDetectionCirclesSource', {
    type: 'geojson',
    data: vehicleCircles,
  })

  map.addLayer({
    id: 'vehicleActivityDetectionCirclesLayer',
    type: 'fill',
    source: 'vehicleActivityDetectionCirclesSource',
    layout: {},
    paint: {
      'fill-color': '#d02621',
      'fill-opacity': ['case', ['get', 'isMarkerActive'], 0.5, 0],
    },
  })

  map.addLayer({
    id: 'vehicleActivityDetectionCirclesOutline',
    type: 'line',
    source: 'vehicleActivityDetectionCirclesSource',
    layout: {},
    paint: {
      'line-color': '#d02621',
      'line-width': 0.2,
    },
  })
}
