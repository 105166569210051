//Import components
import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../store/slices/auth/authSlice'
import appReducer from '../store/slices/app/appSlice'
import mapMarkersReducer from '../store/slices/mapMarkers/mapMarkersSlice'
import mapLocationsReducer from '../store/slices/mapLocations/locationsSlice'
import geofencingZonesReducer from '../store/slices/geofencingZones/geofencingZonesSlice'
import vehiclesReducer from '../store/slices/vehicles/vehiclesSlice'
import vehicleNotificationsReducer from '../store/slices/vehicleNotifications/vehicleNotificationsSlice'
import { authCheckMiddleware, permissionCheckMiddleware } from './middlewares'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
    geofencingZones: geofencingZonesReducer,
    vehicles: vehiclesReducer,
    mapMarkers: mapMarkersReducer,
    mapLocations: mapLocationsReducer,
    vehicleNotifications: vehicleNotificationsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }).concat(authCheckMiddleware, permissionCheckMiddleware),
  devTools: true,
})
