//Import components
import backgroundPolyPc from '../../assets/images/login/background-poly-pc.svg'
import logoPc from '../../assets/images/login/logo-pc.svg'
import * as MdIcons from 'react-icons/md'

// Import styles
import '../../assets/styles/components/login/loginPC.css'

export default function LoginPC({ handleSubmit, handleLoginInput, login, handlePasswordInput, password }) {
  return (
    <section className="login-page-container-pc">
      <div
        className="login-poly-background"
        style={{
          backgroundImage: `url(${backgroundPolyPc})`,
        }}
      >
        <MdIcons.MdElectricScooter />
        <MdIcons.MdElectricBike />
        <MdIcons.MdElectricMoped />
        <MdIcons.MdElectricCar />
        <MdIcons.MdElectricRickshaw />
      </div>
      {/* Dedicated for correct center of login form*/}
      <div className="login-center"></div>
      <div className="login-container">
        <div className="login-form-container">
          <h1> Login to your account:</h1>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <input placeholder="Login" type="text" id="login" onChange={handleLoginInput} value={login} required />
            </div>

            <div className="input-container">
              <input placeholder="Password" type="password" id="password" onChange={handlePasswordInput} value={password} required />
            </div>

            <div className="btn-container">
              <button className="login-btn">Login</button>
            </div>
          </form>
        </div>
      </div>
      <div className="login-logo-container">
        <div
          className="login-logo"
          style={{
            backgroundImage: `url(${logoPc})`,
          }}
        ></div>
      </div>
    </section>
  )
}
