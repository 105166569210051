//Import components
import { NotificationsWindowListItem } from './NotificationsWindowListItem'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getVehicleNotificationsData } from '../../../store/slices/vehicleNotifications/vehicleNotificationsAction'
import { useDispatch, useSelector } from 'react-redux'
import { appendNotifications } from '../../../store/slices/vehicleNotifications/vehicleNotificationsSlice'
import { PullDownToRefreshList } from './PullDownToRefreshList'

//Import styles
import '../../../assets/styles/components/notifications/notificationsMenu/infiniteScroll.css'

export const InfiniteScroll = ({
  lastMapPopupVehiclesMarkersElementData,
  notificationsWindowListContainerRef,
  isRefreshListByFiltersRequested,
  isGetRefreshListDataByFilterRequested,
  setIsNotificationWindowOpened,
}) => {
  const {
    loading: isLoadingNotificationsData,
    error: getNotificationsDataError,
    success: getNotificationsDataSuccess,
    vehicleNotificationsData,
    appendedNotificationsList,
    dateFilterValue,
    notificationTypeFilterValue,
    vehicleIdFilterValue,
  } = useSelector(state => state.vehicleNotifications)
  const dispatch = useDispatch()
  const intersectionObserverRef = useRef()
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)

  useEffect(() => {
    if (!isLoadingMoreData) {
      setIsLoadingMoreData(true)
      dispatch(
        getVehicleNotificationsData({
          pageNumber: pageNum,
          typeFilter: notificationTypeFilterValue,
          vehicleIdFilter: vehicleIdFilterValue,
          startDateFilter: dateFilterValue?.[0],
          endDateFilter: dateFilterValue?.[1],
        })
      )
    }
  }, [pageNum])

  useEffect(() => {
    if (isLoadingMoreData && !isLoadingNotificationsData && getNotificationsDataSuccess) {
      dispatch(appendNotifications(vehicleNotificationsData))
      setHasNextPage(Boolean(vehicleNotificationsData.length))
      setIsLoadingMoreData(false)
    }
    if (isLoadingMoreData && !isLoadingNotificationsData && (!getNotificationsDataSuccess || getNotificationsDataError)) {
      setHasNextPage(false)
      setIsLoadingMoreData(false)
    }
  }, [dispatch, isLoadingMoreData, isLoadingNotificationsData, vehicleNotificationsData, getNotificationsDataError, getNotificationsDataSuccess])

  const lastNotificationRef = useCallback(
    notification => {
      if (isLoadingMoreData) {
        return
      }

      if (intersectionObserverRef.current) {
        intersectionObserverRef.current.disconnect()
      }

      intersectionObserverRef.current = new IntersectionObserver(notifications => {
        if (notifications[0].isIntersecting && hasNextPage) {
          setPageNum(prev => prev + 1)
        }
      })

      if (notification) {
        intersectionObserverRef.current.observe(notification)
      }
    },
    [isLoadingMoreData, hasNextPage]
  )

  // Trigger refresh list by filters
  useEffect(() => {
    if (isRefreshListByFiltersRequested && isGetRefreshListDataByFilterRequested) {
      setIsLoadingMoreData(true)
    }
  }, [isRefreshListByFiltersRequested, isGetRefreshListDataByFilterRequested])

  if (getNotificationsDataError) {
    return <p className="infinite-scroll-error">Error: {getNotificationsDataError}</p>
  }

  if (!appendedNotificationsList.length && isLoadingNotificationsData) {
    return (
      <>
        <div className="infinite-scroll-loading-spinner-container">
          <div className="infinite-scroll-loading-spinner" />
        </div>
      </>
    )
  } else if (!appendedNotificationsList.length && getNotificationsDataSuccess) {
    return <p className="infinite-scroll-error">No such data</p>
  }

  const content = appendedNotificationsList.map((item, id) => {
    if (appendedNotificationsList.length === id + 1) {
      return (
        <NotificationsWindowListItem
          ref={lastNotificationRef}
          item={item}
          id={id}
          key={id}
          lastMapPopupVehiclesMarkersElementData={lastMapPopupVehiclesMarkersElementData}
          setIsNotificationWindowOpened={setIsNotificationWindowOpened}
        />
      )
    }
    return (
      <NotificationsWindowListItem
        item={item}
        id={id}
        key={id}
        lastMapPopupVehiclesMarkersElementData={lastMapPopupVehiclesMarkersElementData}
        setIsNotificationWindowOpened={setIsNotificationWindowOpened}
      />
    )
  })

  const scrollToTop = () => {
    if (notificationsWindowListContainerRef.current) {
      notificationsWindowListContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <>
      <PullDownToRefreshList
        notificationsWindowListContainerRef={notificationsWindowListContainerRef}
        isRefreshListByFiltersRequested={isRefreshListByFiltersRequested}
        isGetRefreshListDataByFilterRequested={isGetRefreshListDataByFilterRequested}
      />
      {content}
      {(isLoadingMoreData &&
        !isRefreshListByFiltersRequested &&
        !isGetRefreshListDataByFilterRequested &&
        (!appendedNotificationsList?.length || appendedNotificationsList?.length > 5)) ||
      (isLoadingMoreData && isRefreshListByFiltersRequested && isGetRefreshListDataByFilterRequested && appendedNotificationsList?.length > 5) ? (
        <div className="infinite-scroll-loading-spinner-container">
          <div className="infinite-scroll-loading-spinner" />
        </div>
      ) : null}
      {appendedNotificationsList?.length > 5 && (
        <div className="back-to-top-button" onClick={scrollToTop}>
          Back to Top
        </div>
      )}
    </>
  )
}
