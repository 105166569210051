//Import components
import { useEffect, useRef, useState } from 'react'
import * as GrIcons from 'react-icons/gr'
import { useSelector } from 'react-redux'

//Import styles
import '../../assets/styles/components/selectLocation/selectLocationBar.css'
import { SelectLocationResultsList } from './SelectLocationResultsList'

export const SelectLocationBar = ({ mapRefData }) => {
  const { locationsData, locationIndex } = useSelector(state => state.mapLocations)
  const [opened, setOpened] = useState(false)
  const [currentSelectedLocationIndex, setCurrentSelectedLocationIndex] = useState(locationIndex)
  const [selectLocationResults, setSelectLocationResults] = useState([])
  const dropDownRef = useRef()

  useEffect(() => {
    let handler = e => {
      if (!dropDownRef.current.contains(e.target)) {
        setOpened(false)
        setSelectLocationResults(null)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  function triggerHandleClick() {
    setOpened(prevOpened => !prevOpened)
    if (!opened && locationIndex) setCurrentSelectedLocationIndex(Number(locationIndex))
    setSelectLocationResults(!opened ? locationsData : null)
  }

  return (
    <>
      <div ref={dropDownRef} className="select-location-dropdown-wrapper" onMouseDown={() => triggerHandleClick()}>
        <GrIcons.GrMapLocation />
        <div className=" select-location-dropdown-wrapper-name">{locationsData ? locationsData[currentSelectedLocationIndex].name : 'Location'}</div>
      </div>
      {selectLocationResults && selectLocationResults.length > 0 && (
        <SelectLocationResultsList
          result={selectLocationResults}
          mapRefData={mapRefData}
          currentSelectedLocationIndex={currentSelectedLocationIndex}
          setCurrentSelectedLocationIndex={setCurrentSelectedLocationIndex}
        />
      )}
    </>
  )
}
