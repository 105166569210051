//Import components
import { useDispatch } from 'react-redux'
import { setNotificationTypeFilterValue } from '../../../../../store/slices/vehicleNotifications/vehicleNotificationsSlice'

//Import styles
import '../../../../../assets/styles/components/notifications/notificationsMenu/notificationsFilterMenu/notificationTypeSearch/searchResult.css'

export const NotificationTypeSearchResult = ({ result, typeInputValue, setTypeInputValue, setIsClosedDropdown, setIsFilterValueSelected }) => {
  const dispatch = useDispatch()

  return (
    <div
      className={`notification-type-search-result ${typeInputValue === result ? 'active' : 'inactive'}`}
      onMouseDown={() => {
        setTypeInputValue(result)
        setIsClosedDropdown(true)
        setIsFilterValueSelected(true)
        dispatch(setNotificationTypeFilterValue(result))
      }}
    >
      <strong>Type:</strong> {result}
    </div>
  )
}
