//Import styles
import '../assets/styles/pages/error.css'

export default function Error() {
  return (
    <section className="error-page-container">
      <p>Oops, something went wrong</p>
    </section>
  )
}
