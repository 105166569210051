//Import components
import React, { useCallback, useEffect, useRef, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import { useDispatch, useSelector } from 'react-redux'
import { addDisableDarkModeBtnRule, removeDisableDarkModeBtnRule } from '../../store/slices/app/appSlice'
import setGeofencingZonesLayer from './actions/setGeofencingZonesLayer'
import setMapMarkers from './actions/updateMarker/setMapMarkers'
import { notificationToast, notificationToastTypes } from '../Notifications/NotificationToast'
import { getLocationsData } from '../../store/slices/mapLocations/locationsAction'
import { prependNotifications } from '../../store/slices/vehicleNotifications/vehicleNotificationsSlice'

//Import styles
import 'mapbox-gl/dist/mapbox-gl.css'
import '../../assets/styles/components/vehiclesMap.css'
import setVehiclesLayer from './actions/setVehiclesLayer'

// Duck map
export default function VehiclesMap({
  setMapRefData,
  setLastMapPopupVehiclesMarkersElementData,
  setLastMapVehiclesMarkersData,
  setForceRerenderByMarkerUpdate,
}) {
  const { REACT_APP_MAPBOX_PUBLIC_KEY } = process.env
  const { darkMode } = useSelector(state => state.app)
  const { locationsData, locationIndex } = useSelector(state => state.mapLocations)

  const dispatch = useDispatch()
  const locationsDataRef = useRef()
  const locationIndexRef = useRef(0)
  const mapPopupVehiclesMarkersRef = useRef({}) // Html map markers (for popup)
  const mapContainer = useRef()
  const map = useRef(null)
  const closeMarkerPopupCenterTimeoutIdRef = useRef()
  const [isObtainVehiclesDataError, setIsObtainVehiclesDataError] = useState(false)
  const [isObtainGeofencingZonesDataError, setIsObtainGeofencingZonesDataError] = useState(false)
  const vehiclesDataRef = useRef({}) // Latest vehicles data
  const vehiclesLayerDataRef = useRef({ type: 'FeatureCollection', features: [] })
  const geofencingZonesLayersDataRef = useRef({
    grayArea: {
      type: 'FeatureCollection',
      features: [],
    },
    speedLimitZones: {
      type: 'FeatureCollection',
      features: [],
    },
    noParkingZones: {
      type: 'FeatureCollection',
      features: [],
    },
    parkingZones: {
      type: 'FeatureCollection',
      features: [],
    },
  }) // Read latest zones data

  /* For testing only */
  const vehiclesMoveDetectionCircleDataRef = useRef({})
  const vehiclesMoveDetectionCircleLayerDataRef = useRef({ type: 'FeatureCollection', features: [] })
  const vehiclesActivityDetectionCircleDataRef = useRef({})
  const vehiclesActivityDetectionCircleLayerDataRef = useRef({ type: 'FeatureCollection', features: [] })
  /* For testing only */

  const [mapLng, setMapLng] = useState(28.853089275547035)
  const [mapLat, setMapLat] = useState(47.0211152983203)
  const [mapZoom, setMapZoom] = useState(12)
  const webSocketDataRef = useRef({ webSocketRef: null, isSocketClosed: false, webSocketReconnectionTimeoutId: null, isSilentCloseSocket: false })

  mapboxgl.accessToken = REACT_APP_MAPBOX_PUBLIC_KEY

  // Intended to create popup vehicle marker or update existing
  const createOrUpdateMapMarker = useCallback(
    singleMarkerData => {
      // Wait for map to initialize
      if (!map.current || !singleMarkerData) {
        return
      }

      // Intended to create html marker or update existing
      setMapMarkers(
        dispatch,
        locationsDataRef,
        locationIndexRef,
        map,
        mapPopupVehiclesMarkersRef,
        singleMarkerData,
        closeMarkerPopupCenterTimeoutIdRef,
        setLastMapPopupVehiclesMarkersElementData,
        setLastMapVehiclesMarkersData,
        setForceRerenderByMarkerUpdate,
        vehiclesDataRef,
        vehiclesLayerDataRef,
        vehiclesMoveDetectionCircleDataRef,
        vehiclesMoveDetectionCircleLayerDataRef,
        vehiclesActivityDetectionCircleDataRef,
        vehiclesActivityDetectionCircleLayerDataRef
      )
    },
    [dispatch, setForceRerenderByMarkerUpdate, setLastMapPopupVehiclesMarkersElementData, setLastMapVehiclesMarkersData]
  )

  // Socket connection
  const webSocketConnection = useCallback(() => {
    const url = new URL(process.env.REACT_APP_MAP_SERVER_SOCKET_API_URL)
    const serverHost = url.host // This includes both the hostname and the port
    let socketUrl

    if (process.env.REACT_APP_START_TYPE === 'dev') {
      socketUrl = `ws://${serverHost}`
    } else {
      socketUrl = `wss://${serverHost}`
    }

    const ws = new WebSocket(socketUrl, [process.env.REACT_APP_MAP_SERVER_API_SOCKET_CONNECTION_TOKEN])
    webSocketDataRef.current.webSocketRef = ws

    ws.onmessage = message => {
      try {
        const event = JSON.parse(message.data).event
        const data = JSON.parse(message.data).payload

        if (webSocketDataRef.current?.isSocketClosed) {
          webSocketDataRef.current.isSocketClosed = false
          notificationToast({ notificationType: notificationToastTypes.socketRestored, message: 'Socket connection was restored! Updating data!' })
        }

        if (event === 'vehicleData') {
          if (data) {
            vehiclesDataRef.current[data.properties.vehicle_id] = data // Collect vehicles data
            createOrUpdateMapMarker(data) // Intended to create popup vehicle marker or update existing

            setIsObtainVehiclesDataError(false)
          } else {
            setIsObtainVehiclesDataError(true)
          }
        }

        if (event === 'geofencingZonesData') {
          if (data) {
            geofencingZonesLayersDataRef.current = data
            updateGeofencingZonesLayers()
            setIsObtainGeofencingZonesDataError(false)
          } else {
            setIsObtainGeofencingZonesDataError(true)
          }
        }

        if (event === 'vehicleNotification') {
          if (data) {
            notificationToast({
              notificationType: data.type,
              batteryPct: data.currentBatteryPct,
              date: data.sentNotificationDate,
              currentSpeed: data.currentSpeed,
              vehicleId: data.vehicleId,
              vehicleMovingData: data.vehicleMovingData,
            })
            dispatch(prependNotifications(data))
          }
        }
      } catch (error) {
        console.error('WebSocket - error parsing JSON message', error)
        ws.close()
      }
    }

    ws.onclose = message => {
      console.error('WebSocket connection closed')

      if (webSocketDataRef.current?.isSilentCloseSocket) {
        return
      }

      notificationToast({
        notificationType: notificationToastTypes.socketDetached,
        message: `Socket connection was lost! Trying to reconnect! Don't close this page!`,
      })

      webSocketDataRef.current.isSocketClosed = true
      webSocketDataRef.current.webSocketReconnectionTimeoutId = setTimeout(function () {
        webSocketConnection()
      }, 15000)
    }

    ws.onerror = error => {
      console.error('WebSocket error')
      ws.close()
    }
  }, [dispatch, createOrUpdateMapMarker])

  // Internet connection
  useEffect(() => {
    const closeMarkerPopupCenterTimeoutId = closeMarkerPopupCenterTimeoutIdRef.current
    const currentWebSocketData = webSocketDataRef.current

    // Lost internet connection (Show message and close socket if it has connection)
    function lostInternetConnection() {
      notificationToast({
        notificationType: notificationToastTypes.connectionLost,
        message: 'Internet connection lost! Check your internet connection, contact your support manager to get help if this error appear randomly.',
      })
      if (webSocketDataRef.current?.webSocketRef) {
        webSocketDataRef.current.webSocketRef.close()
      }
    }

    // Restored internet connection (Show message and try socket connection)
    function restoredInternetConnection() {
      notificationToast({ notificationType: notificationToastTypes.connectionRestored, message: 'Internet connection restored! Restarting socket' })
      dispatch(getLocationsData()) // Get again locations list
      webSocketConnection()
    }

    webSocketConnection()
    window.addEventListener('online', restoredInternetConnection)
    window.addEventListener('offline', lostInternetConnection)

    return () => {
      window.removeEventListener('online', restoredInternetConnection)
      window.removeEventListener('offline', lostInternetConnection)

      if (currentWebSocketData?.webSocketRef) {
        currentWebSocketData.isSilentCloseSocket = true
        currentWebSocketData.webSocketRef.close()
      }

      clearTimeout(currentWebSocketData?.webSocketReconnectionTimeoutId) // Clear timeout for socket reconnection
      clearTimeout(closeMarkerPopupCenterTimeoutId) // Clear timeout for map center after marker popup close
    }
  }, [dispatch, webSocketConnection])

  // Show error during obtaining data
  useEffect(() => {
    // Wait for map to initialize
    if (!map.current) {
      return
    }

    if (isObtainGeofencingZonesDataError) {
      notificationToast({
        notificationType: notificationToastTypes.warning,
        message:
          'An error occurred due updating zones data. Try to refresh this page if error appears more than once. Contact your support manager to get help if error shows continue.',
      })
    }
    if (isObtainVehiclesDataError) {
      notificationToast({
        notificationType: notificationToastTypes.warning,
        message:
          'An error occurred due updating Markers data. Try to refresh this page if error appears more than once. Contact your support manager to get help if error shows continue.',
      })
    }
  }, [isObtainGeofencingZonesDataError, isObtainVehiclesDataError])

  // Change location from dropdown when redux state changes
  useEffect(() => {
    locationIndexRef.current = locationIndex
    locationsDataRef.current = locationsData

    if (locationsData) {
      setMapLng(locationsData[Number(locationIndex)].location.longitude)
      setMapLat(locationsData[Number(locationIndex)].location.latitude)
      setMapZoom(locationsData[Number(locationIndex)].zoom)
    }

    if (locationsData && map.current) {
      clearTimeout(closeMarkerPopupCenterTimeoutIdRef.current) // Clear timout for map center after marker popup close

      map.current.flyTo({
        center: [locationsData[Number(locationIndex)].location.longitude, locationsData[Number(locationIndex)].location.latitude],
        zoom: locationsData[Number(locationIndex)].zoom,
        duration: 6000,
        essential: true,
      })
    }
  }, [locationsData, locationIndex])

  // Get locations list
  useEffect(() => {
    dispatch(getLocationsData()) // Get initial locations list data
  }, [dispatch])

  // Show dark mode button after map style is loaded and one of the geofencing zones is loaded
  const showDarkModeButton = useCallback(() => {
    dispatch(removeDisableDarkModeBtnRule('map-is-not-fully-loaded'))
  }, [dispatch])

  //Listen to light mode change and update style //map.current.loaded()
  useEffect(() => {
    // Wait for map to initialize
    if (map.current) {
      dispatch(addDisableDarkModeBtnRule('map-is-not-fully-loaded')) // Hide dark mode button until new map style is not loaded

      if (darkMode) {
        map.current.setStyle('mapbox://styles/mapbox/dark-v11', { diff: false })
      } else {
        map.current.setStyle('mapbox://styles/mapbox/light-v11', { diff: false })
      }

      // Set new layers after style change
      map.current.on('style.load', setNewMapLayers) // Triggered when `setStyle` is called
      map.current.on('style.load', showDarkModeButton) // Triggered when `setStyle` is called
    }

    // Cleanup function
    return () => {
      if (map.current) {
        map.current.off('style.load', setNewMapLayers)
        map.current.off('style.load', showDarkModeButton) // Triggered when `setStyle` is called
      }
    }
  }, [darkMode, dispatch, showDarkModeButton])

  // Map initialisation
  useEffect(() => {
    // Initialize map only once
    if (!map.current) {
      // Hide dark mode button until map is not loaded
      dispatch(addDisableDarkModeBtnRule('map-is-not-fully-loaded'))

      // VehiclesMap initialisation
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        projection: 'globe',
        style: darkMode ? 'mapbox://styles/mapbox/dark-v11' : 'mapbox://styles/mapbox/light-v11',
        center: [mapLng, mapLat],
        zoom: mapZoom,
        pitchWithRotate: false,
        rotateWithView: false,
        trackResize: true,
        fadeDuration: 0,
      })

      // Add full screen button
      map.current.addControl(new mapboxgl.FullscreenControl())

      // Add zoom and rotation controls to the map
      map.current.addControl(new mapboxgl.NavigationControl())

      // Add geolocate control to the map.
      map.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
        })
      )

      map.current.on('style.load', setAtmosphereStyle)
      map.current.on('style.load', setNewMapLayers) // Initialize map zones layers
      map.current.on('style.load', showDarkModeButton)

      setMapRefData(map.current) // Transfer map reference
    }

    // Set atmosphere style (colors, stars)
    function setAtmosphereStyle() {
      map.current.setFog({
        range: [0.8, 8],
        color: '#dc9f9f',
        'horizon-blend': 0.5,
        'high-color': '#245bde',
        'space-color': '#000000',
        'star-intensity': 0.15,
      })
    }

    // Cleanup function
    return () => {
      if (map.current) {
        map.current.off('style.load', setAtmosphereStyle)
        map.current.off('style.load', setNewMapLayers)
        map.current.off('style.load', showDarkModeButton)
      }
    }
  }, [darkMode, dispatch, mapLat, mapLng, mapZoom, setMapRefData, showDarkModeButton])

  // Set new map layers
  function setNewMapLayers() {
    if (!map.current.getLayer('grayAreaLayer') && !map.current.getLayer('grayAreaOutlineLayer')) {
      setGeofencingZonesLayer(map.current, geofencingZonesLayersDataRef.current, 'grayAreaLayer')
    }
    if (!map.current.getLayer('speedLimitZonesLayer') && !map.current.getLayer('speedLimitZonesOutlineLayer')) {
      setGeofencingZonesLayer(map.current, geofencingZonesLayersDataRef.current, 'speedLimitZonesLayer')
    }
    if (!map.current.getLayer('parkingZonesLayer') && !map.current.getLayer('parkingZonesOutlineLayer')) {
      setGeofencingZonesLayer(map.current, geofencingZonesLayersDataRef.current, 'parkingZonesLayer')
    }
    if (!map.current.getLayer('noParkingZonesLayer') && !map.current.getLayer('noParkingZonesOutlineLayer')) {
      setGeofencingZonesLayer(map.current, geofencingZonesLayersDataRef.current, 'noParkingZonesLayer')
    }

    if (!map.current.getLayer('vehiclesLayer')) {
      setVehiclesLayer(map.current, vehiclesLayerDataRef.current)
    }
  }

  // Updates the geofencing zones layer data
  function updateGeofencingZonesLayers() {
    // Wait for map to initialize and update source for geofencing zones layers
    if (
      !map.current ||
      !geofencingZonesLayersDataRef?.current ||
      (geofencingZonesLayersDataRef?.current && !Object.keys(geofencingZonesLayersDataRef?.current).length)
    ) {
      return
    }

    // Update zones layers
    if (map.current.getSource('grayAreaSource') && geofencingZonesLayersDataRef.current?.grayArea) {
      map.current.getSource('grayAreaSource').setData(geofencingZonesLayersDataRef.current.grayArea) // Update geofencing zones layer source
    }
    if (map.current.getSource('speedLimitZonesSource') && geofencingZonesLayersDataRef.current?.speedLimitZones) {
      map.current.getSource('speedLimitZonesSource').setData(geofencingZonesLayersDataRef.current.speedLimitZones) // Update geofencing zones layer source
    }
    if (map.current.getSource('parkingZonesSource') && geofencingZonesLayersDataRef.current?.parkingZones) {
      map.current.getSource('parkingZonesSource').setData(geofencingZonesLayersDataRef.current.parkingZones) // Update geofencing zones layer source
    }
    if (map.current.getSource('noParkingZonesSource') && geofencingZonesLayersDataRef.current?.noParkingZones) {
      map.current.getSource('noParkingZonesSource').setData(geofencingZonesLayersDataRef.current.noParkingZones) // Update geofencing zones layer source
    }
  }

  return <div ref={mapContainer} className="map-container" />
}
