//Import components
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function CheckUserIsLogged() {
  const { userToken } = useSelector(state => state.auth)
  const location = useLocation()

  return userToken ? <Navigate to="/home" state={{ from: location }} replace /> : <Outlet />
}
