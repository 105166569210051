import { TailSpin } from 'react-loader-spinner'

//Import styles
import '../assets/styles/components/loader.css'

// Intended to show spinner while loading
export default function Loader() {
  return (
    <div className="loader">
      <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />
    </div>
  )
}
