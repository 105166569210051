//Import components
import { useEffect, useRef, useState } from 'react'
import * as IoIcons from 'react-icons/io'
import { NotificationsWindow } from './NotificationsWindow'
import { resetAppendedNotificationsList } from '../../../store/slices/vehicleNotifications/vehicleNotificationsSlice'
import { useDispatch } from 'react-redux'

//Import styles
import '../../../assets/styles/components/notifications/notificationsMenu/notificationsBar.css'

export const NotificationsBar = ({ lastMapPopupVehiclesMarkersElementData }) => {
  const dispatch = useDispatch()
  const [isNotificationWindowOpened, setIsNotificationWindowOpened] = useState(false)
  const dropDownRef = useRef()
  const notificationsWindowRef = useRef()
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
  const [isFilterValueSelected, setIsFilterValueSelected] = useState(false)

  useEffect(() => {
    let handler = e => {
      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(e.target) &&
        notificationsWindowRef.current &&
        !notificationsWindowRef.current.contains(e.target)
      ) {
        if (isDatePickerOpened) {
          return
        }

        if (isFilterValueSelected) {
          setIsFilterValueSelected(false)
          return
        }
        setIsNotificationWindowOpened(false)
        dispatch(resetAppendedNotificationsList())
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [dispatch, isDatePickerOpened, isFilterValueSelected])

  function triggerHandleClick() {
    setIsNotificationWindowOpened(prevOpened => !prevOpened)
    dispatch(resetAppendedNotificationsList())
  }

  return (
    <>
      <div ref={dropDownRef} className="notifications-dropdown-wrapper" onMouseDown={() => triggerHandleClick()}>
        <IoIcons.IoIosNotificationsOutline />
        <div className="notifications-dropdown-wrapper-name">Notifications</div>
      </div>
      {isNotificationWindowOpened && (
        <NotificationsWindow
          notificationsWindowRef={notificationsWindowRef}
          lastMapPopupVehiclesMarkersElementData={lastMapPopupVehiclesMarkersElementData}
          setIsNotificationWindowOpened={setIsNotificationWindowOpened}
          setIsDatePickerOpened={setIsDatePickerOpened}
          setIsFilterValueSelected={setIsFilterValueSelected}
        />
      )}
    </>
  )
}
