//Import components
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { userReLogin } from '../store/slices/auth/authActions'

export default function RequireAuth() {
  const { userToken } = useSelector(state => state.auth)
  const location = useLocation()
  const dispatch = useDispatch()

  // Re login user
  useEffect(() => {
    if (userToken) {
      dispatch(userReLogin())
    }
  }, [])

  return userToken ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
}
