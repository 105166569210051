//Import images
import alertVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Alert.svg'
import availableVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Available.svg'
import inUseVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/InUse.svg'
import reservedVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Reserved.svg'
import dischargedVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Discharged.svg'
import chargingVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Charging.svg'
import needsInvestigationVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/NeedsInvestigation.svg'
import stolenVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Stolen.svg'
import notReadyVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/NotReady.svg'
import transportationVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Transportation.svg'
import maintenanceVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Maintenance.svg'
import storageVehicleMarkerCircle from '../../../assets/images/mapImages/Markers/scootersCircle/Storage.svg'

// Dedicated to chose marker image
export default function chooseStatisticsMenuMarkerImage(status) {
  switch (status) {
    case 'Battery < 4%':
      return `url(${alertVehicleMarkerCircle})`
    case 'Available':
      return `url(${availableVehicleMarkerCircle})`
    case 'In use':
      return `url(${inUseVehicleMarkerCircle})`
    case 'Reserved':
      return `url(${reservedVehicleMarkerCircle})`
    case 'Discharged':
      return `url(${dischargedVehicleMarkerCircle})`
    case 'Charging':
      return `url(${chargingVehicleMarkerCircle})`
    case 'Needs investigation':
      return `url(${needsInvestigationVehicleMarkerCircle})`
    case 'Stolen':
      return `url(${stolenVehicleMarkerCircle})`
    case 'Not ready':
      return `url(${notReadyVehicleMarkerCircle})`
    case 'Transportation':
      return `url(${transportationVehicleMarkerCircle})`
    case 'Maintenance':
      return `url(${maintenanceVehicleMarkerCircle})`
    case 'Storage':
      return `url(${storageVehicleMarkerCircle})`
    default:
      return `url(${maintenanceVehicleMarkerCircle})`
  }
}
