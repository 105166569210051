//Import components
import * as FaIcons from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { VehicleIdSearchResultsList } from './SearchResultList'
import { getVehiclesIdsData } from '../../../../../store/slices/vehicles/vehiclesAction'

//Import styles
import '../../../../../assets/styles/components/notifications/notificationsMenu/notificationsFilterMenu/vehicleIdSearch/searchBar.css'

export const VehicleIdSearchBar = ({ vehicleIdInputValue, setVehicleIdInputValue, setIsFilterValueSelected }) => {
  const { vehicleIdFilterValue } = useSelector(state => state.vehicleNotifications)
  const {
    loading: isLoadingVehiclesIdsData,
    error: getVehiclesIdsDataError,
    success: getVehiclesIdsDataSuccess,
    vehiclesIdsData,
  } = useSelector(state => state.vehicles)
  const dispatch = useDispatch()
  const [searchResults, setSearchResults] = useState([])
  const [isClosedDropdown, setIsClosedDropdown] = useState(true)

  const handleVehicleIdChange = value => {
    if (value === '') {
      setVehicleIdInputValue(value)
      getData(' ')
    } else if (!value) {
      setVehicleIdInputValue('')
      getData('')
    } else {
      setVehicleIdInputValue(value)
      getData(value)
    }
  }

  const getData = inputValue => {
    let results

    if (!vehiclesIdsData || !vehiclesIdsData?.length || inputValue === '') {
      results = null
    } else {
      results = vehiclesIdsData.filter(({ vehicle_id }) => {
        if (inputValue === ' ') {
          return true
        } else if (
          // Search by markerId
          vehicle_id.includes(inputValue.toLowerCase())
        ) {
          return true
        }
        return false
      })
    }
    setSearchResults(results)
  }

  useEffect(() => {
    if (!isClosedDropdown && !isLoadingVehiclesIdsData && getVehiclesIdsDataSuccess) {
      getData(' ')
    }
  }, [vehiclesIdsData, getVehiclesIdsDataSuccess, isClosedDropdown, isLoadingVehiclesIdsData])

  useEffect(() => {
    if (!isClosedDropdown) {
      dispatch(getVehiclesIdsData({}))
    }
  }, [dispatch, isClosedDropdown])

  return (
    <>
      <div className="vehicle-id-input-wrapper">
        <input
          placeholder="Vehicle id"
          value={vehicleIdInputValue}
          onClick={() => {
            getData(' ')
            setIsClosedDropdown(false)
          }}
          onBlur={() => {
            if (vehicleIdInputValue !== vehicleIdFilterValue) {
              handleVehicleIdChange(vehicleIdFilterValue)
            }
            setIsClosedDropdown(true)
          }}
          onChange={e => {
            handleVehicleIdChange(e.target.value)
          }}
          onFocus={e => {
            e.preventDefault()
          }}
        />
        <FaIcons.FaSearch id="vehicle-id-input-wrapper-icon" />
        {!isClosedDropdown && (
          <VehicleIdSearchResultsList
            result={searchResults}
            vehicleIdInputValue={vehicleIdInputValue}
            setVehicleIdInputValue={setVehicleIdInputValue}
            setIsClosedDropdown={setIsClosedDropdown}
            isLoadingVehiclesIdsData={isLoadingVehiclesIdsData}
            getVehiclesIdsDataError={getVehiclesIdsDataError}
            setIsFilterValueSelected={setIsFilterValueSelected}
          />
        )}
      </div>
    </>
  )
}
