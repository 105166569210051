//Import components
import { SelectLocationResult } from './SelectLocationResult'

//Import styles
import '../../assets/styles/components/selectLocation/selectLocationResultsList.css'

export const SelectLocationResultsList = ({ result, mapRefData, currentSelectedLocationIndex, setCurrentSelectedLocationIndex }) => {
  return (
    <div className="select-results-list">
      {result.map((result, id) => {
        return (
          <SelectLocationResult
            result={result}
            mapRefData={mapRefData}
            index={id}
            currentSelectedLocationIndex={currentSelectedLocationIndex}
            setCurrentSelectedLocationIndex={setCurrentSelectedLocationIndex}
            key={id}
          />
        )
      })}
    </div>
  )
}
