//Import components
import backgroundPolyMobile from '../../assets/images/login/background-poly-mobile.svg'
import logoMobile from '../../assets/images/login/logo-mobile.svg'

// Import styles
import '../../assets/styles/components/login/loginMobile.css'
import * as MdIcons from 'react-icons/md'

export default function LoginMobile({ handleSubmit, handleLoginInput, login, handlePasswordInput, password }) {
  return (
    <section className="login-page-container-mobile">
      <div
        className="login-poly-background-top"
        style={{
          backgroundImage: `url(${backgroundPolyMobile})`,
        }}
      >
        <MdIcons.MdElectricScooter />
        <MdIcons.MdElectricBike />
        <MdIcons.MdElectricMoped />
        <MdIcons.MdElectricCar />
        <MdIcons.MdElectricRickshaw />
      </div>
      <div className="login-container">
        <div className="login-form-container">
          <h1> Login to your account:</h1>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <input placeholder="Login" type="text" id="login" onChange={handleLoginInput} value={login} required />
            </div>

            <div className="input-container">
              <input placeholder="Password" type="password" id="password" onChange={handlePasswordInput} value={password} required />
            </div>

            <div className="btn-container">
              <button className="login-btn">Login</button>
            </div>
          </form>
        </div>
      </div>
      <div
        className="login-poly-background-bottom"
        style={{
          backgroundImage: `url(${backgroundPolyMobile})`,
        }}
      >
        <div
          className="login-logo"
          style={{
            backgroundImage: `url(${logoMobile})`,
          }}
        ></div>
      </div>
    </section>
  )
}
