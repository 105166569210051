//Import components
import * as GrIcons from 'react-icons/gr'
import * as VscIcons from 'react-icons/vsc'
import * as HiIcons from 'react-icons/hi'
import * as TbIcons from 'react-icons/tb'
import * as BsIcons from 'react-icons/bs'
import * as MdIcons from 'react-icons/md'
import { toast } from 'react-toastify'

//Import styles
import '../../assets/styles/components/notifications/notificationToast.css'

export const notificationToastTypes = {
  success: 'success',
  permissionDenied: 'permission denied',
  warning: 'warning',
  connectionLost: 'connection lost',
  connectionRestored: 'connection restored',
  socketDetached: 'socket detached',
  socketRestored: 'socket restored',
  batteryAlert: 'battery alert', // This notification comes when the scooter battery charge below 4% is held for more than 5 minutes.
  batteryOk: 'battery ok', // This notification comes when some scooter was with a battery charge below 4% and then its charge increased.
  vehicleMoving: 'vehicle moving', // This notification should be triggered when the scooter in any status OTHER than Transportation moves more than 150 meters. The notification will repeat and the distance traveled will increase until the scooter stops.
  vehicleStoppedMoving: 'vehicle stopped moving', // This notification should be triggered when the scooter in any status BUT Transportation stops and has not moved for more than 20 minutes. It will display the start and stop point of the route and the distance traveled in a straight line.
  speedLimit: 'speed limit', // This notification will be triggered if the scooter is traveling in any status BEFORE Transportation at a speed exceeding the 40 km/h speed limit.
  outOfParkingZone: 'out of parking zone', // This notification will be triggered if the scooter was in a parking zone and has left the parking zone in any status other than Transportation and In use.
  inParkingZone: 'in parking zone', // This notification will be triggered if the scooter was outside the parking zone and will return to the parking zone in any status other than Transportation and In use.
  inNoGoZone: 'in no go zone', // This notification will be triggered if the scooter in any status other than Transportation enters a stop zone. (Black Zone)
  exitNoGoZone: 'exit no go zone',
  offline: 'offline', // This notification will be triggered when there have been no changes to “Updated” for more than 5 hours.
  online: 'online', // This notification will be triggered when a previously non-existent scooter appears or if there have been no changes to “Updated” for more than 5 hours and then they appear.
}

export function notificationToast(
  notificationData = {
    notificationType: null,
    date: null,
    vehicleId: null,
    batteryPct: null,
    currentSpeed: null,
    vehicleMovingData: null,
    message: null,
  },
  isToast = true,
  darkMode = false,
  isExistOnMap = true
) {
  const { notificationType, date, vehicleId = '', batteryPct, currentSpeed, vehicleMovingData, message = '' } = notificationData
  let notification

  switch (notificationType) {
    case notificationToastTypes.success:
      notification = notificationContainer(<GrIcons.GrCheckmark color="green" />, 'Success', message, date, isExistOnMap)
      break
    case notificationToastTypes.permissionDenied:
      notification = notificationContainer(<TbIcons.TbHandStop color="red" />, 'Permission denied', message, date, isExistOnMap)
      break
    case notificationToastTypes.warning:
      notification = notificationContainer(<HiIcons.HiOutlineLightBulb color="orange" />, 'Warning', message, date, isExistOnMap)
      break
    case notificationToastTypes.connectionLost:
      notification = notificationContainer(<VscIcons.VscRadioTower color="red" />, 'Connection lost', message, date, isExistOnMap)
      break
    case notificationToastTypes.connectionRestored:
      notification = notificationContainer(<VscIcons.VscRadioTower color="green" />, 'Connection restored', message, date, isExistOnMap)
      break
    case notificationToastTypes.socketDetached:
      notification = notificationContainer(<BsIcons.BsPlug color="red" />, 'Socket detached', message, date, isExistOnMap)
      break
    case notificationToastTypes.socketRestored:
      notification = notificationContainer(<BsIcons.BsPlug color="green" />, 'Socket restored', message, date, isExistOnMap)
      break
    case notificationToastTypes.batteryAlert:
      notification = notificationContainer(
        <MdIcons.MdBatteryAlert color="red" />,
        'Vehicle battery alert',
        `Vehicle ${vehicleId} - has ${batteryPct.toFixed()}% battery! Pay attention!`,
        date,
        isExistOnMap
      )
      break
    case notificationToastTypes.batteryOk:
      notification = notificationContainer(
        <MdIcons.MdBatteryChargingFull color="green" />,
        'Vehicle battery ok',
        `Vehicle ${vehicleId} - has ${batteryPct.toFixed()}% battery! Vehicle restored!`,
        date,
        isExistOnMap
      )
      break
    case notificationToastTypes.vehicleMoving:
      notification = notificationContainer(
        <MdIcons.MdOutlineDirectionsCar color="red" />,
        'Vehicle moving',
        <>
          Vehicle {vehicleId} - is moving! Pay attention! Is {formatDistance(vehicleMovingData?.traveledDistance)} in{' '}
          {formatTime(vehicleMovingData?.traveledDistanceTime)}. Start location:
          <a
            href={`https://www.google.com/maps/place/${vehicleMovingData?.startLocation?.latitude},${vehicleMovingData?.startLocation?.longitude}`}
            rel={'noopener noreferrer'}
            target={'_blank'}
            onMouseDown={e => e.stopPropagation()}
          >
            Click
          </a>
        </>,
        date,
        isExistOnMap
      )
      break
    case notificationToastTypes.vehicleStoppedMoving:
      notification = notificationContainer(
        <MdIcons.MdOutlineDirectionsCar color="green" />,
        'Vehicle stopped moving',
        <>
          Vehicle {vehicleId} - is stopped! First vehicle location:
          <a
            href={`https://www.google.com/maps/place/${vehicleMovingData?.startLocation?.latitude},${vehicleMovingData?.startLocation?.longitude}`}
            rel={'noopener noreferrer'}
            target={'_blank'}
            onMouseDown={e => e.stopPropagation()}
          >
            Click
          </a>
          , and current vehicle location:
          <a
            href={`https://www.google.com/maps/place/${vehicleMovingData?.stopLocation?.latitude},${vehicleMovingData?.stopLocation?.longitude}`}
            rel={'noopener noreferrer'}
            target={'_blank'}
            onMouseDown={e => e.stopPropagation()}
          >
            Click
          </a>
          , with distance in a straight line: {formatDistance(vehicleMovingData?.traveledDistance)}.
        </>,
        date,
        isExistOnMap
      )
      break
    case notificationToastTypes.speedLimit:
      notification = notificationContainer(
        <MdIcons.MdSpeed color="green" />,
        'Vehicle speed limit',
        `Vehicle ${vehicleId} - has reached 55 km/h limit! Pay attention! Current speed ${currentSpeed.toFixed()} km/h!`,
        date,
        isExistOnMap
      )
      break
    case notificationToastTypes.outOfParkingZone:
      notification = notificationContainer(
        <GrIcons.GrMapLocation color="red" />,
        'Vehicle out of parking zone',
        `Vehicle ${vehicleId} - is located out of parking zone! Please pay attention!`,
        date,
        isExistOnMap
      )
      break
    case notificationToastTypes.inParkingZone:
      notification = notificationContainer(
        <GrIcons.GrMapLocation color="green" />,
        'Vehicle in parking zone',
        `Vehicle ${vehicleId} - is located in parking zone now! Please pay attention!`,
        date,
        isExistOnMap
      )
      break
    case notificationToastTypes.inNoGoZone:
      notification = notificationContainer(
        <GrIcons.GrMapLocation color="yellow" />,
        'Vehicle in no-go zone',
        `Vehicle ${vehicleId} - is located in no-go zone! Contact the customer and tell to come back!`,
        date,
        isExistOnMap
      )
      break
    case notificationToastTypes.exitNoGoZone:
      notification = notificationContainer(
        <GrIcons.GrMapLocation color="green" />,
        'Vehicle exit no-go zone',
        `Vehicle ${vehicleId} - is located out of no-go zone! Please pay attention!`,
        date,
        isExistOnMap
      )
      break
    case notificationToastTypes.offline:
      notification = notificationContainer(
        <VscIcons.VscRadioTower color="red" />,
        'Vehicle offline',
        `Vehicle ${vehicleId} - is not sending new data to the server! Please verify vehicle status and last signal!`,
        date,
        isExistOnMap
      )
      break
    case notificationToastTypes.online:
      notification = notificationContainer(
        <VscIcons.VscRadioTower color="green" />,
        'Vehicle online',
        `Vehicle ${vehicleId} - is sending new data now! Please pay attention!`,
        date,
        isExistOnMap
      )
      break
    default:
      break
  }

  return isToast ? toast(notification, { theme: darkMode ? 'dark' : 'light' }) : notification
}

function notificationContainer(icon, name, message, date, isExistOnMap = true, locations) {
  let notificationDate
  let newDate = new Date()

  if (date) {
    newDate = new Date(date)
  }

  const formattedDate = newDate.toDateString()
  const hours = newDate.getHours().toString().padStart(2, '0')
  const minutes = newDate.getMinutes().toString().padStart(2, '0')
  notificationDate = `${formattedDate}  ${hours}:${minutes}`

  return (
    <div className="notification-container" style={!isExistOnMap ? { backgroundColor: 'lightgray' } : {}}>
      <div className="notification-container-type">
        {icon}
        <b>
          <p>{name}</p>
        </b>
      </div>
      <div className="notification-container-message">{isExistOnMap ? <p>{message} </p> : <p>{message} (MARKER DOES NOT SHOWS ON MAP)</p>}</div>
      <div className="notification-container-time">
        <p>{notificationDate}</p>
      </div>
    </div>
  )
}

function formatTime(seconds) {
  if (seconds < 60) {
    return seconds + ' second' + (seconds !== 1 ? 's' : '')
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60)
    return minutes + ' minute' + (minutes !== 1 ? 's' : '')
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600)
    return hours + ' hour' + (hours !== 1 ? 's' : '')
  } else {
    const days = Math.floor(seconds / 86400)
    return days + ' day' + (days !== 1 ? 's' : '')
  }
}

function formatDistance(meters) {
  if (meters < 1000) {
    return meters.toFixed(1) + ' m'
  } else {
    const kilometers = (meters / 1000).toFixed(1)
    return kilometers + ' km'
  }
}
