//Import components
import { useDispatch } from 'react-redux'
import { setVehicleIdFilterValue } from '../../../../../store/slices/vehicleNotifications/vehicleNotificationsSlice'

//Import styles
import '../../../../../assets/styles/components/notifications/notificationsMenu/notificationsFilterMenu/vehicleIdSearch/searchResult.css'
import React from 'react'

export const VehicleIdSearchResult = ({ result, vehicleIdInputValue, setVehicleIdInputValue, setIsClosedDropdown, setIsFilterValueSelected }) => {
  const dispatch = useDispatch()
  return (
    <div
      className={`vehicle-id-search-result ${vehicleIdInputValue === result.vehicle_id ? 'active' : 'inactive'}`}
      onMouseDown={() => {
        setVehicleIdInputValue(result.vehicle_id)
        setIsClosedDropdown(true)
        setIsFilterValueSelected(true)
        dispatch(setVehicleIdFilterValue(result.vehicle_id))
      }}
    >
      <strong>ID:</strong> {result.vehicle_id}
    </div>
  )
}
