import { createSlice } from '@reduxjs/toolkit'
import { getLocationsData } from './locationsAction.js'

// Initialize from local storage
const locationIndex = localStorage.getItem('locationIndex') ? Number(localStorage.getItem('locationIndex')) : 0

const initialState = {
  loading: false,
  locationsData: null,
  locationIndex: locationIndex || 0,
  error: null,
  success: false,
}

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocation: (state, action) => {
      localStorage.setItem('locationIndex', action.payload) // store user's token in local storage
      state.locationIndex = Number(action.payload)
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLocationsData.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(getLocationsData.fulfilled, (state, action) => {
        state.loading = false
        state.locationsData = action.payload
        state.success = true
      })
      .addCase(getLocationsData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.success = false
      })
  },
})

export const { setLocation } = locationsSlice.actions

export default locationsSlice.reducer
