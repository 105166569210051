//Import components
import { NotificationsWindowList } from './NotificationsWindowList'
import { NotificationsWindowControls } from './NotificationsWindowControls'
import { useState } from 'react'

//Import styles
import '../../../assets/styles/components/notifications/notificationsMenu/notificationsWindow.css'

export const NotificationsWindow = ({
  notificationsWindowRef,
  lastMapPopupVehiclesMarkersElementData,
  setIsNotificationWindowOpened,
  setIsDatePickerOpened,
  setIsFilterValueSelected,
}) => {
  const [isRefreshListByFiltersRequested, setIsRefreshListByFiltersRequested] = useState(false)
  const [isGetRefreshListDataByFilterRequested, setIsGetRefreshListDataByFilterRequested] = useState(false)

  return (
    <div ref={notificationsWindowRef} className="notifications-window-container">
      <div className="notifications-window-name">
        <p>Notifications center</p>
      </div>
      <NotificationsWindowControls
        isRefreshListByFiltersRequested={isRefreshListByFiltersRequested}
        isGetRefreshListDataByFilterRequested={isGetRefreshListDataByFilterRequested}
        setIsRefreshListByFiltersRequested={setIsRefreshListByFiltersRequested}
        setIsGetRefreshListDataByFilterRequested={setIsGetRefreshListDataByFilterRequested}
        setIsDatePickerOpened={setIsDatePickerOpened}
        setIsFilterValueSelected={setIsFilterValueSelected}
      />
      <NotificationsWindowList
        lastMapPopupVehiclesMarkersElementData={lastMapPopupVehiclesMarkersElementData}
        isRefreshListByFiltersRequested={isRefreshListByFiltersRequested}
        isGetRefreshListDataByFilterRequested={isGetRefreshListDataByFilterRequested}
        setIsNotificationWindowOpened={setIsNotificationWindowOpened}
      />
    </div>
  )
}
