//Import components
import { logout } from '../store/slices/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useMatch, useResolvedPath } from 'react-router-dom'
import { appDarkMode } from '../store/slices/app/appSlice'
import * as MdIcons from 'react-icons/md'
import * as BiIcons from 'react-icons/bi'
import * as BsIcons from 'react-icons/bs'
import { VehicleSearchBar } from './VehiclesSearch/SearchBar'
import { SelectLocationBar } from './SelectLocation/SelectLocationBar'
import { NotificationsBar } from './Notifications/NotificationsMenu/NotificationsBar'

//Import styles
import '../assets/styles/components/navbar.css'

export default function Navbar({ mapRefData, lastMapPopupVehiclesMarkersElementData, searchMapMarkersData, forceRerenderByMarkerUpdate }) {
  const { darkMode, disableDarkModeBtnRules } = useSelector(state => state.app)
  const dispatch = useDispatch()
  const location = useLocation()

  return (
    <nav className="navigation-container">
      <span>
        <ul>
          <CustomLink className="home-logo-link" to="/home">
            <div className="home-logo" />
          </CustomLink>
          <a className="about-link" href="https://t.me/+4t8XjoBK8sUxN2Ni" target="_blank" rel="noopener noreferrer">
            <BsIcons.BsInfoCircle />
          </a>
        </ul>
      </span>

      {location.pathname === '/home' && (
        <span>
          <ul>
            <div className="search-bar-container">
              <VehicleSearchBar
                searchMapMarkersData={searchMapMarkersData}
                lastMapPopupVehiclesMarkersElementData={lastMapPopupVehiclesMarkersElementData}
                forceRerenderByMarkerUpdate={forceRerenderByMarkerUpdate}
              />
            </div>
          </ul>
        </span>
      )}

      {location.pathname === '/home' && (
        <span>
          <ul>
            <div className="select-location-bar-container">
              <SelectLocationBar mapRefData={mapRefData} />
            </div>
          </ul>
        </span>
      )}

      {location.pathname === '/home' && (
        <span>
          <ul>
            <div className="notifications-bar-container">
              <NotificationsBar lastMapPopupVehiclesMarkersElementData={lastMapPopupVehiclesMarkersElementData} />
            </div>
          </ul>
        </span>
      )}

      <span>
        <ul>
          <div className="color-mode-btn-container">
            <button
              className="color-mode-btn"
              onClick={() => (darkMode ? dispatch(appDarkMode(false)) : dispatch(appDarkMode(true)))}
              style={{ display: disableDarkModeBtnRules[0] ? 'none' : 'block' }}
            >
              {darkMode ? <MdIcons.MdLightMode /> : <MdIcons.MdDarkMode />} Theme
            </button>
          </div>

          <button className="logout-btn" onClick={() => dispatch(logout())}>
            <BiIcons.BiLogOut /> Logout
          </button>
        </ul>
      </span>
    </nav>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? 'active' : ''}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}
