//Import components
import * as FaIcons from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { NotificationTypeSearchResultsList } from './SearchResultList'
import { notificationToastTypes } from '../../../NotificationToast'

//Import styles
import '../../../../../assets/styles/components/notifications/notificationsMenu/notificationsFilterMenu/notificationTypeSearch/searchBar.css'

export const NotificationTypeSearchBar = ({ typeInputValue, setTypeInputValue, setIsFilterValueSelected }) => {
  const { notificationTypeFilterValue } = useSelector(state => state.vehicleNotifications)
  const [searchResults, setSearchResults] = useState([])
  const [isClosedDropdown, setIsClosedDropdown] = useState(true)

  const handleTypeChange = value => {
    if (value === '') {
      setTypeInputValue(value)
      getData(' ')
    } else if (!value) {
      setTypeInputValue('')
      getData('')
    } else {
      setTypeInputValue(value)
      getData(value)
    }
  }

  const getData = inputValue => {
    const excludedTypes = [
      notificationToastTypes.success,
      notificationToastTypes.permissionDenied,
      notificationToastTypes.warning,
      notificationToastTypes.connectionLost,
      notificationToastTypes.connectionRestored,
      notificationToastTypes.socketDetached,
      notificationToastTypes.socketRestored,
    ]
    let results

    if (!Object.keys(notificationToastTypes).length || inputValue === '') {
      results = null
    } else {
      results = Object.entries(notificationToastTypes)
        .filter(([key, value]) => !excludedTypes.includes(value))
        .filter(([key, value]) => {
          if (inputValue === ' ') {
            return true
          } else if (
            // Search by type
            value.toLowerCase().includes(inputValue.toLowerCase())
          ) {
            return value
          }
          return null
        })
        .map(([key, value]) => value)
    }
    setSearchResults(results)
  }

  return (
    <>
      <div className="notification-type-input-wrapper">
        <input
          placeholder="Notification type"
          value={typeInputValue}
          onClick={() => {
            getData(' ')
            setIsClosedDropdown(false)
          }}
          onBlur={() => {
            if (typeInputValue !== notificationTypeFilterValue) {
              handleTypeChange(notificationTypeFilterValue)
            }
            setIsClosedDropdown(true)
          }}
          onChange={e => {
            handleTypeChange(e.target.value)
          }}
          onFocus={e => {
            e.preventDefault()
          }}
        />
        <FaIcons.FaSearch id="notification-type-input-wrapper-icon" />
        {!isClosedDropdown && (
          <NotificationTypeSearchResultsList
            result={searchResults}
            typeInputValue={typeInputValue}
            setTypeInputValue={setTypeInputValue}
            setIsClosedDropdown={setIsClosedDropdown}
            setIsFilterValueSelected={setIsFilterValueSelected}
          />
        )}
      </div>
    </>
  )
}
