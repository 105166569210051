import { logout } from './slices/auth/authSlice'
import { notificationToast, notificationToastTypes } from '../components/Notifications/NotificationToast'

// Auth middleware
export const authCheckMiddleware = store => next => action => {
  if (action.type.endsWith('/rejected') && action.payload) {
    // Check if the error is a 401 (Unauthorized)
    if (action.payload.status === 401) {
      store.dispatch(logout())
      notificationToast({ notificationType: notificationToastTypes.permissionDenied, message: 'Auth failed! Unauthorized!' })
      return null // Terminate the action here
    }
  }

  // Pass the action to the next middleware or reducer
  return next(action)
}

// Permission check middleware
export const permissionCheckMiddleware = store => next => action => {
  if (action.type.endsWith('/rejected') && action.payload) {
    // Check if the error is 403 (Forbidden)
    if (action.payload.status === 403) {
      notificationToast({ notificationType: notificationToastTypes.permissionDenied, message: 'Permission denied! You are not allowed to do this!' })
      return null // Terminate the action here
    }
  }

  // Pass the action to the next middleware or reducer
  return next(action)
}
