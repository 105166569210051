//Import components
import { notificationToast } from '../NotificationToast'
import React from 'react'
import { resetAppendedNotificationsList } from '../../../store/slices/vehicleNotifications/vehicleNotificationsSlice'
import { useDispatch } from 'react-redux'

//Import styles
import '../../../assets/styles/components/notifications/notificationsMenu/notificationsWindowListItem.css'

export const NotificationsWindowListItem = React.forwardRef(
  ({ id, item, lastMapPopupVehiclesMarkersElementData = {}, setIsNotificationWindowOpened }, ref) => {
    const dispatch = useDispatch()
    const isExistOnMap = lastMapPopupVehiclesMarkersElementData?.hasOwnProperty(item.vehicleId) || false
    return (
      <div
        ref={ref ? ref : null}
        className="notifications-window-list-item"
        onMouseDown={() => {
          //Check if object is not empty
          if (Object.keys(lastMapPopupVehiclesMarkersElementData)?.length !== 0 && isExistOnMap) {
            for (let marker in lastMapPopupVehiclesMarkersElementData) {
              if (lastMapPopupVehiclesMarkersElementData[marker].getPopup().isOpen()) {
                lastMapPopupVehiclesMarkersElementData[marker].togglePopup() // Close popup if it is opened
              }
            }
            lastMapPopupVehiclesMarkersElementData[item.vehicleId].togglePopup() // Open new popup
            setIsNotificationWindowOpened(false)
            dispatch(resetAppendedNotificationsList())
          }
        }}
      >
        {notificationToast(
          {
            notificationType: item.type,
            date: item.createdAt,
            vehicleId: item.vehicleId,
            batteryPct: item.currentBatteryPct,
            currentSpeed: item.currentSpeed,
            vehicleMovingData: item.vehicleMovingData,
          },
          false,
          false,
          isExistOnMap
        )}
      </div>
    )
  }
)
