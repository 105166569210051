//Import components
import { useRef } from 'react'
import { InfiniteScroll } from './InfiniteScroll'

//Import styles
import '../../../assets/styles/components/notifications/notificationsMenu/notificationsWindowList.css'

export const NotificationsWindowList = ({
  lastMapPopupVehiclesMarkersElementData,
  isRefreshListByFiltersRequested,
  isGetRefreshListDataByFilterRequested,
  setIsNotificationWindowOpened,
}) => {
  const notificationsWindowListContainerRef = useRef(null)

  return (
    <div className="notifications-window-list" ref={notificationsWindowListContainerRef}>
      <InfiniteScroll
        lastMapPopupVehiclesMarkersElementData={lastMapPopupVehiclesMarkersElementData}
        notificationsWindowListContainerRef={notificationsWindowListContainerRef}
        isRefreshListByFiltersRequested={isRefreshListByFiltersRequested}
        isGetRefreshListDataByFilterRequested={isGetRefreshListDataByFilterRequested}
        setIsNotificationWindowOpened={setIsNotificationWindowOpened}
      />
    </div>
  )
}
