import { createSlice } from '@reduxjs/toolkit'

// Initialize from local storage
const darkMode = localStorage.getItem('darkMode') ? localStorage.getItem('darkMode') : 'false'

const initialState = {
  loading: false,
  darkMode: JSON.parse(darkMode),
  disableDarkModeBtnRules: [],
  error: null,
  success: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    appDarkMode: (state, action) => {
      state.darkMode = action.payload
      localStorage.setItem('darkMode', JSON.stringify(action.payload)) // store application dark mode
    },
    addDisableDarkModeBtnRule: (state, action) => {
      if (!state.disableDarkModeBtnRules.includes(action.payload)) {
        state.disableDarkModeBtnRules.push(action.payload)
      }
    },
    removeDisableDarkModeBtnRule: (state, action) => {
      state.disableDarkModeBtnRules = state.disableDarkModeBtnRules.filter(rule => rule !== action.payload)
    },
  },
})

export const { appDarkMode, addDisableDarkModeBtnRule, removeDisableDarkModeBtnRule } = appSlice.actions

export default appSlice.reducer
