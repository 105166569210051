import { createSlice } from '@reduxjs/toolkit'
import { userLogin, userReLogin } from './authActions'

// Initialize from local storage
const userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null
const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : null

const initialState = {
  loading: false,
  userId,
  userToken,
  error: null,
  success: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      localStorage.removeItem('userToken') // delete token from storage
      localStorage.removeItem('userId') // delete user id from storage
      state.loading = false
      state.userInfo = null
      state.userId = null
      state.userToken = null
      state.error = null
    },
    setCredentials: (state, action) => {
      state.loading = false
      state.userId = action.payload._id
      state.userInfo = action.payload
      state.error = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(userLogin.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        localStorage.setItem('userToken', action.payload.token) // store user's token in local storage
        localStorage.setItem('userId', action.payload._id) // store user's id in local storage
        state.loading = false
        state.userInfo = action.payload
        state.userId = action.payload._id
        state.userToken = action.payload.token
        state.success = true
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.success = false
      })
      .addCase(userReLogin.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(userReLogin.fulfilled, (state, action) => {
        localStorage.setItem('userToken', action.payload.token) // store user's token in local storage
        localStorage.setItem('userId', action.payload._id) // store user's id in local storage
        state.loading = false
        state.userInfo = action.payload
        state.userId = action.payload._id
        state.userToken = action.payload.token
        state.success = true
      })
      .addCase(userReLogin.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.success = false
      })
  },
})

export const { logout, setCredentials } = authSlice.actions

export default authSlice.reducer
