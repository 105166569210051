//Import components
import { VehicleSearchResult } from './SearchResult'

//Import styles
import '../../assets/styles/components/vehiclesSearch/searchResultsList.css'

export const VehicleSearchResultsList = ({ result, lastMapPopupVehiclesMarkersElementData }) => {
  return (
    <div className="vehicle-search-results-list">
      {result.map((result, id) => {
        return <VehicleSearchResult result={result} key={id} lastMapPopupVehiclesMarkersElementData={lastMapPopupVehiclesMarkersElementData} />
      })}
    </div>
  )
}
