//Import styles
import '../../assets/styles/components/vehiclesSearch/searchResult.css'

export const VehicleSearchResult = ({ result, lastMapPopupVehiclesMarkersElementData = {} }) => {
  return (
    <div
      className="vehicle-search-result"
      onMouseDown={() => {
        //Check if object is not empty
        if (Object.keys(lastMapPopupVehiclesMarkersElementData)?.length !== 0) {
          for (let marker in lastMapPopupVehiclesMarkersElementData) {
            if (lastMapPopupVehiclesMarkersElementData[marker].getPopup().isOpen()) {
              lastMapPopupVehiclesMarkersElementData[marker].togglePopup() // Close popup if it is opened
            }
          }
          lastMapPopupVehiclesMarkersElementData[result.markerId].togglePopup() // Open new popup
        }
      }}
    >
      <strong>ID:</strong> {result.markerId} <br /> <strong>Status:</strong> {result?.properties?.custom_vehicle_status}
    </div>
  )
}
