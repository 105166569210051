//Import components
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import { store } from './store'
import { ToastContainer, Slide, toast } from 'react-toastify'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import PWAPrompt from 'react-ios-pwa-prompt'

//Import styles
import './assets/styles/app/index.css'
import 'react-toastify/dist/ReactToastify.css'
import './assets/styles/components/notifications/toastStyles.css'

window.addEventListener(
  'blur',
  () => {
    toast.dismiss()
  },
  { passive: false }
)

//Initialization root HTML component
ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt="Close" permanentlyHideOnDismiss={false} />
    <ToastContainer
      style={{ zIndex: 1 }}
      position="bottom-right"
      pauseOnFocusLoss={false}
      autoClose={4000}
      hideProgressBar
      newestOnBottom
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
      theme="light"
      closeButton={false}
      transition={Slide}
      limit={4}
      className="toast-container"
    />
    <App />
  </Provider>
)

serviceWorkerRegistration.unregister()
reportWebVitals()
