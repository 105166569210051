//Import images
import battery5to0 from '../assets/images/mapImages/Popup/Battery/0.svg'
import battery10to5 from '../assets/images/mapImages/Popup/Battery/5.svg'
import battery15to10 from '../assets/images/mapImages/Popup/Battery/10.svg'
import battery20to15 from '../assets/images/mapImages/Popup/Battery/15.svg'
import battery25to20 from '../assets/images/mapImages/Popup/Battery/20.svg'
import battery30to25 from '../assets/images/mapImages/Popup/Battery/25.svg'
import battery35to30 from '../assets/images/mapImages/Popup/Battery/30.svg'
import battery40to35 from '../assets/images/mapImages/Popup/Battery/35.svg'
import battery45to40 from '../assets/images/mapImages/Popup/Battery/45.svg'
import battery50to45 from '../assets/images/mapImages/Popup/Battery/50.svg'
import battery55to50 from '../assets/images/mapImages/Popup/Battery/55.svg'
import battery65to55 from '../assets/images/mapImages/Popup/Battery/65.svg'
import battery75to65 from '../assets/images/mapImages/Popup/Battery/75.svg'
import battery85to75 from '../assets/images/mapImages/Popup/Battery/85.svg'
import battery95to85 from '../assets/images/mapImages/Popup/Battery/95.svg'
import battery100to95 from '../assets/images/mapImages/Popup/Battery/100.svg'
import fuel from '../assets/images/mapImages/Popup/Icons/Fuel.svg'
import Bicycle from '../assets/images/mapImages/Popup/VehicleType/bicycle.png'
import Car from '../assets/images/mapImages/Popup/VehicleType/car.png'
import DeliveryBike from '../assets/images/mapImages/Popup/VehicleType/delivery-bike.png'
import ForkLift from '../assets/images/mapImages/Popup/VehicleType/forklift.png'
import GolfCart from '../assets/images/mapImages/Popup/VehicleType/golf-cart.png'
import Motorbike from '../assets/images/mapImages/Popup/VehicleType/motorbike.png'
import KickScooter from '../assets/images/mapImages/Popup/VehicleType/kick-scooter.png'
import Van from '../assets/images/mapImages/Popup/VehicleType/van.png'
import LowBattery from '../assets/images/mapImages/Popup/VehicleStatuses/Alert.svg'
import Available from '../assets/images/mapImages/Popup/VehicleStatuses/Avalable.svg'
import InUse from '../assets/images/mapImages/Popup/VehicleStatuses/InUse.svg'
import Reserved from '../assets/images/mapImages/Popup/VehicleStatuses/Reserved.svg'
import Discharged from '../assets/images/mapImages/Popup/VehicleStatuses/Discharged.svg'
import NeedsInvestigation from '../assets/images/mapImages/Popup/VehicleStatuses/NeedsInvestigation.svg'
import Stolen from '../assets/images/mapImages/Popup/VehicleStatuses/Stolen.svg'
import NotReady from '../assets/images/mapImages/Popup/VehicleStatuses/Notready.svg'
import Transportation from '../assets/images/mapImages/Popup/VehicleStatuses/Transportation.svg'
import Charging from '../assets/images/mapImages/Popup/VehicleStatuses/Charging.svg'
import Maintenance from '../assets/images/mapImages/Popup/VehicleStatuses/Maintenance.svg'
import Storage from '../assets/images/mapImages/Popup/VehicleStatuses/Storage.svg'

// Dedicated for battery and for fuel
export function choosePopupBatteryImage(batteryValue, vehiclePropulsionType) {
  if (vehiclePropulsionType !== 'electric') {
    return `url(${fuel})`
  }

  if (batteryValue >= 0 && batteryValue < 5) {
    return `url(${battery5to0})`
  } else if (batteryValue >= 5 && batteryValue < 10) {
    return `url(${battery10to5})`
  } else if (batteryValue >= 10 && batteryValue < 15) {
    return `url(${battery15to10})`
  } else if (batteryValue >= 15 && batteryValue < 20) {
    return `url(${battery20to15})`
  } else if (batteryValue >= 20 && batteryValue < 25) {
    return `url(${battery25to20})`
  } else if (batteryValue >= 25 && batteryValue < 30) {
    return `url(${battery30to25})`
  } else if (batteryValue >= 30 && batteryValue < 35) {
    return `url(${battery35to30})`
  } else if (batteryValue >= 35 && batteryValue < 40) {
    return `url(${battery40to35})`
  } else if (batteryValue >= 40 && batteryValue < 45) {
    return `url(${battery45to40})`
  } else if (batteryValue >= 45 && batteryValue < 50) {
    return `url(${battery50to45})`
  } else if (batteryValue >= 50 && batteryValue < 55) {
    return `url(${battery55to50})`
  } else if (batteryValue >= 55 && batteryValue < 65) {
    return `url(${battery65to55})`
  } else if (batteryValue >= 65 && batteryValue < 75) {
    return `url(${battery75to65})`
  } else if (batteryValue >= 75 && batteryValue < 85) {
    return `url(${battery85to75})`
  } else if (batteryValue >= 85 && batteryValue < 95) {
    return `url(${battery95to85})`
  } else {
    return `url(${battery100to95})`
  }
}

// Dedicated for vehicle type
export function choosePopupVehicleTypeImage(vehicleTypeValue) {
  //(Note! Does not fully correspond with MDS)
  if (vehicleTypeValue === 'bicycle') {
    return `url(${Bicycle})`
  } else if (vehicleTypeValue === 'car') {
    return `url(${Car})`
  } else if (vehicleTypeValue === 'delivery-bike') {
    return `url(${DeliveryBike})`
  } else if (vehicleTypeValue === 'forklift') {
    return `url(${ForkLift})`
  } else if (vehicleTypeValue === 'golf-cart') {
    return `url(${GolfCart})`
  } else if (vehicleTypeValue === 'motorbike') {
    return `url(${Motorbike})`
  } else if (vehicleTypeValue === 'scooter') {
    return `url(${KickScooter})`
  } else if (vehicleTypeValue === 'van') {
    return `url(${Van})`
  } else {
    return `url(${KickScooter})`
  }
}

// Dedicated for vehicle custom status
export function choosePopupVehicleCustomStatusImage(markerCustomStatus) {
  let vehicleStatus
  let vehicleStatusImg

  if (markerCustomStatus === 'Battery < 4%') {
    vehicleStatus = 'Battery < 4%'
    vehicleStatusImg = `url(${LowBattery})`
  } else if (markerCustomStatus === 'Available') {
    vehicleStatus = 'Available'
    vehicleStatusImg = `url(${Available})`
  } else if (markerCustomStatus === 'In use') {
    vehicleStatus = 'In use'
    vehicleStatusImg = `url(${InUse})`
  } else if (markerCustomStatus === 'Reserved') {
    vehicleStatus = 'Reserved'
    vehicleStatusImg = `url(${Reserved})`
  } else if (markerCustomStatus === 'Discharged') {
    vehicleStatus = 'Discharged'
    vehicleStatusImg = `url(${Discharged})`
  } else if (markerCustomStatus === 'Needs investigation') {
    vehicleStatus = 'Needs investigation'
    vehicleStatusImg = `url(${NeedsInvestigation})`
  } else if (markerCustomStatus === 'Stolen') {
    vehicleStatus = 'Stolen'
    vehicleStatusImg = `url(${Stolen})`
  } else if (markerCustomStatus === 'Not ready') {
    vehicleStatus = 'Not ready'
    vehicleStatusImg = `url(${NotReady})`
  } else if (markerCustomStatus === 'Transportation') {
    vehicleStatus = 'Transportation'
    vehicleStatusImg = `url(${Transportation})`
  } else if (markerCustomStatus === 'Charging') {
    vehicleStatus = 'Charging'
    vehicleStatusImg = `url(${Charging})`
  } else if (markerCustomStatus === 'Storage') {
    vehicleStatus = 'Storage'
    vehicleStatusImg = `url(${Storage})`
  } else {
    vehicleStatus = 'Maintenance'
    vehicleStatusImg = `url(${Maintenance})`
  }

  return { vehicleStatus, vehicleStatusImg }
}

// Intended to return how long from now in text variant (timestamp - seconds)
export function timeAgo(timestamp) {
  if (!timestamp) {
    return undefined
  }

  const nowInSeconds = Math.floor(Date.now() / 1000)
  const differenceInSeconds = nowInSeconds - timestamp

  if (differenceInSeconds < 60) {
    return `${differenceInSeconds} second${differenceInSeconds !== 1 ? 's' : ''} ago`
  }

  const differenceInMinutes = Math.floor(differenceInSeconds / 60)
  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} minute${differenceInMinutes !== 1 ? 's' : ''} ago`
  }

  const differenceInHours = Math.floor(differenceInMinutes / 60)
  if (differenceInHours < 24) {
    return `${differenceInHours} hour${differenceInHours !== 1 ? 's' : ''} ago`
  }

  const differenceInDays = Math.floor(differenceInHours / 24)
  if (differenceInDays < 365) {
    return `${differenceInDays} day${differenceInDays !== 1 ? 's' : ''} ago`
  }

  const differenceInYears = Math.floor(differenceInDays / 365)
  return `${differenceInYears} year${differenceInYears !== 1 ? 's' : ''} ago`
}
