//Import components
import { useDispatch, useSelector } from 'react-redux'
import {
  resetAppendedNotificationsList,
  setDateFilterValue,
  setNotificationTypeFilterValue,
  setVehicleIdFilterValue,
} from '../../../../store/slices/vehicleNotifications/vehicleNotificationsSlice'
import { useEffect, useState } from 'react'
import { VehicleIdSearchBar } from './VehicleIdSearch/SearchBar'
import { NotificationTypeSearchBar } from './NotificationTypeSearch/SearchBar'
import { DatePickerBar } from './DatePicker'
import { getVehicleNotificationsData } from '../../../../store/slices/vehicleNotifications/vehicleNotificationsAction'

//Import styles
import '../../../../assets/styles/components/notifications/notificationsMenu/notificationsFilterMenu/notificationsFilterMenu.css'

export const NotificationsFilterMenu = ({
  isRefreshListByFiltersRequested,
  isGetRefreshListDataByFilterRequested,
  setIsRefreshListByFiltersRequested,
  setIsGetRefreshListDataByFilterRequested,
  setIsDatePickerOpened,
  setIsFilterValueSelected,
}) => {
  const {
    loading: isLoadingNotificationsData,
    success: getNotificationsDataSuccess,
    vehicleNotificationsData,
    dateFilterValue,
    notificationTypeFilterValue,
    vehicleIdFilterValue,
  } = useSelector(state => state.vehicleNotifications)
  const dispatch = useDispatch()
  const [dateInputValue, setDateInputValue] = useState([])
  const [typeInputValue, setTypeInputValue] = useState('')
  const [vehicleIdInputValue, setVehicleIdInputValue] = useState('')

  function handleClearFiltersClick() {
    setDateInputValue([])
    setTypeInputValue('')
    setVehicleIdInputValue('')
    dispatch(setDateFilterValue([]))
    dispatch(setNotificationTypeFilterValue(''))
    dispatch(setVehicleIdFilterValue(''))
  }

  useEffect(() => {
    if (dateFilterValue !== [] || notificationTypeFilterValue !== '' || vehicleIdFilterValue !== '') {
      setIsRefreshListByFiltersRequested(true)
    }
  }, [dateFilterValue, notificationTypeFilterValue, vehicleIdFilterValue, setIsRefreshListByFiltersRequested])

  useEffect(() => {
    const startDateValue = dateInputValue?.[0] ? new Date(dateInputValue?.[0]).toISOString() : null
    const endDateValue = dateInputValue?.[1] ? new Date(dateInputValue?.[1]).toISOString() : null

    if (isRefreshListByFiltersRequested) {
      dispatch(
        getVehicleNotificationsData({
          typeFilter: typeInputValue,
          vehicleIdFilter: vehicleIdInputValue,
          startDateFilter: startDateValue,
          endDateFilter: endDateValue,
        })
      )
      setIsGetRefreshListDataByFilterRequested(true)
    }
  }, [dispatch, isRefreshListByFiltersRequested, setIsGetRefreshListDataByFilterRequested, dateInputValue, typeInputValue, vehicleIdInputValue])

  useEffect(() => {
    if (isRefreshListByFiltersRequested && isGetRefreshListDataByFilterRequested && !isLoadingNotificationsData && getNotificationsDataSuccess) {
      dispatch(resetAppendedNotificationsList())
      setIsRefreshListByFiltersRequested(false)
      setIsGetRefreshListDataByFilterRequested(false)
    }
  }, [
    dispatch,
    isLoadingNotificationsData,
    vehicleNotificationsData,
    getNotificationsDataSuccess,
    isRefreshListByFiltersRequested,
    isGetRefreshListDataByFilterRequested,
    setIsRefreshListByFiltersRequested,
    setIsGetRefreshListDataByFilterRequested,
  ])

  useEffect(() => {
    return () => {
      dispatch(setDateFilterValue([]))
      dispatch(setNotificationTypeFilterValue(''))
      dispatch(setVehicleIdFilterValue(''))
    }
  }, [dispatch])

  return (
    <>
      <div className="notifications-filter-menu">
        <DatePickerBar
          dateInputValue={dateInputValue}
          setDateInputValue={setDateInputValue}
          setIsDatePickerOpened={setIsDatePickerOpened}
          setIsFilterValueSelected={setIsFilterValueSelected}
        />
        <NotificationTypeSearchBar
          typeInputValue={typeInputValue}
          setTypeInputValue={setTypeInputValue}
          setIsFilterValueSelected={setIsFilterValueSelected}
        />
        <VehicleIdSearchBar
          vehicleIdInputValue={vehicleIdInputValue}
          setVehicleIdInputValue={setVehicleIdInputValue}
          setIsFilterValueSelected={setIsFilterValueSelected}
        />

        <div className="clear-filters-button" onClick={handleClearFiltersClick}>
          Clear filters
        </div>
      </div>
    </>
  )
}
