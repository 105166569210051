import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getVehicleNotificationsData = createAsyncThunk(
  'vehicleNotifications/getVehicleNotificationsData',
  async (
    { limit = null, pageNumber = null, typeFilter = null, vehicleIdFilter = null, startDateFilter = null, endDateFilter = null },
    { getState, rejectWithValue }
  ) => {
    const { userId, userToken: token } = getState().auth

    try {
      const queryParams = new URLSearchParams({
        limit: limit || '',
        pageNumber: pageNumber || '',
        typeFilter: typeFilter || '',
        vehicleIdFilter: vehicleIdFilter || '',
        startDateFilter: startDateFilter || '',
        endDateFilter: endDateFilter || '',
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }

      const response = await axios.get(`${process.env.REACT_APP_MAP_SERVER_API_URL}/vehicleNotifications/getAll/${userId}?${queryParams}`, config)

      return response.data.payload
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue({ data: error.response.data, status: error.response.status })
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
