//Import components
import { useCallback, useEffect, useState } from 'react'
import * as FaIcons from 'react-icons/fa'
import { VehicleSearchResultsList } from './SearchResultsList'

//Import styles
import '../../assets/styles/components/vehiclesSearch/searchBar.css'

export const VehicleSearchBar = ({ searchMapMarkersData, lastMapPopupVehiclesMarkersElementData, forceRerenderByMarkerUpdate }) => {
  const [inputValue, setInputValue] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const getData = useCallback(
    inputValue => {
      let results

      if (!searchMapMarkersData || inputValue === '') {
        results = null
      } else {
        results = Object.entries(searchMapMarkersData)
          .filter(([markerId, { properties }]) => {
            if (inputValue === ' ') {
              return true
            } else if (
              // Search by markerId or by status
              markerId?.toLowerCase().includes(inputValue.toLowerCase()) ||
              properties?.custom_vehicle_status?.toLowerCase().includes(inputValue.toLowerCase())
            ) {
              return { markerId, properties }
            }
            return null
          })
          .map(([markerId, { properties }]) => ({ markerId, properties }))
      }
      setSearchResults(results)
    },
    [searchMapMarkersData]
  )

  const handleChange = value => {
    setInputValue(value)
    getData(value)
  }

  useEffect(() => {
    if (inputValue && inputValue !== '') {
      getData(inputValue)
    }
  }, [searchMapMarkersData, forceRerenderByMarkerUpdate, inputValue, getData])

  return (
    <>
      <div className="vehicle-search-input-wrapper">
        <FaIcons.FaSearch id="vehicle-search-input-wrapper-icon" />
        <input
          placeholder="Search vehicles..."
          value={inputValue}
          onClick={() => {
            if (!inputValue || inputValue === '') {
              getData(' ')
            } else {
              getData(inputValue)
            }
          }}
          onBlur={() => {
            handleChange('')
          }}
          onChange={e => {
            handleChange(e.target.value)
          }}
          onFocus={e => {
            e.preventDefault()
          }}
        />
      </div>

      {searchResults && searchResults.length > 0 && (
        <VehicleSearchResultsList result={searchResults} lastMapPopupVehiclesMarkersElementData={lastMapPopupVehiclesMarkersElementData} />
      )}
    </>
  )
}
