//Import components
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getVehicleNotificationsData } from '../../../store/slices/vehicleNotifications/vehicleNotificationsAction'
import { appendNotifications, resetAppendedNotificationsList } from '../../../store/slices/vehicleNotifications/vehicleNotificationsSlice'

//Import styles
import '../../../assets/styles/components/notifications/notificationsMenu/pullDownToRefreshList.css'

export const PullDownToRefreshList = ({
  notificationsWindowListContainerRef,
  isRefreshListByFiltersRequested,
  isGetRefreshListDataByFilterRequested,
}) => {
  const {
    loading: isLoadingNotificationsData,
    error: getNotificationsDataError,
    success: getNotificationsDataSuccess,
    vehicleNotificationsData,
    dateFilterValue,
    notificationTypeFilterValue,
    vehicleIdFilterValue,
  } = useSelector(state => state.vehicleNotifications)
  const dispatch = useDispatch()
  const notificationsWindowListRefreshingSpinnerRef = useRef(null)
  const notificationsWindowListExpansionContainerRef = useRef(null)
  const [isStartedRefreshingData, setIsStartedRefreshingData] = useState(false)
  const [isRefreshSwipeEnded, setIsRefreshSwipeEnded] = useState(false)

  useEffect(() => {
    const listExpansionContainer = notificationsWindowListExpansionContainerRef.current // Dedicated to add gap to the top during list refresh
    const refreshListSpinner = notificationsWindowListRefreshingSpinnerRef.current

    if (isStartedRefreshingData && isRefreshSwipeEnded && !isLoadingNotificationsData) {
      if (getNotificationsDataSuccess) {
        setTimeout(() => {
          setIsStartedRefreshingData(false)
          setIsRefreshSwipeEnded(false)
          listExpansionContainer.style.marginBottom = '1px'
          refreshListSpinner.style.display = 'none'

          dispatch(resetAppendedNotificationsList())
          dispatch(appendNotifications(vehicleNotificationsData))
        }, 450)
      }

      if (!getNotificationsDataSuccess || getNotificationsDataError) {
        setIsStartedRefreshingData(false)
        setIsRefreshSwipeEnded(false)
        listExpansionContainer.style.marginBottom = '1px'
        refreshListSpinner.style.display = 'none'
      }
    }
  }, [
    dispatch,
    isStartedRefreshingData,
    isRefreshSwipeEnded,
    isLoadingNotificationsData,
    getNotificationsDataError,
    vehicleNotificationsData,
    getNotificationsDataSuccess,
  ])

  // Swipe to refresh
  useEffect(() => {
    let initialY = 0
    let currentY = 0
    const listContainer = notificationsWindowListContainerRef.current
    const listExpansionContainer = notificationsWindowListExpansionContainerRef.current
    const refreshListSpinner = notificationsWindowListRefreshingSpinnerRef.current

    const refreshSwipeStart = e => {
      if (!isStartedRefreshingData && listContainer.scrollTop === 0) {
        initialY = e.touches[0].clientY
      }
    }

    const refreshSwipe = e => {
      if (!isStartedRefreshingData && !isRefreshSwipeEnded && !isLoadingNotificationsData && listContainer.scrollTop === 0) {
        currentY = e.touches[0].clientY
        let yDifference = initialY < currentY ? Math.abs(initialY - currentY) : 0

        if (yDifference <= 45) {
          listExpansionContainer.style.marginBottom = `${yDifference - 20}px`
        }

        if (yDifference > 45) {
          setIsStartedRefreshingData(true)
          refreshListSpinner.style.display = 'block'
          dispatch(
            getVehicleNotificationsData({
              typeFilter: notificationTypeFilterValue,
              vehicleIdFilter: vehicleIdFilterValue,
              startDateFilter: dateFilterValue?.[0],
              endDateFilter: dateFilterValue?.[1],
            })
          )
        }
      }
    }

    const refreshSwipeEnd = e => {
      if (isStartedRefreshingData) {
        setIsRefreshSwipeEnded(true)
      } else {
        listExpansionContainer.style.marginBottom = '1px'
      }
    }

    if (listContainer && listExpansionContainer && refreshListSpinner) {
      listContainer.addEventListener('touchstart', refreshSwipeStart)
      listContainer.addEventListener('touchmove', refreshSwipe)
      listContainer.addEventListener('touchend', refreshSwipeEnd)
    }

    return () => {
      if (listContainer && listExpansionContainer && refreshListSpinner) {
        listContainer.removeEventListener('touchstart', refreshSwipeStart)
        listContainer.removeEventListener('touchmove', refreshSwipe)
        listContainer.removeEventListener('touchend', refreshSwipeEnd)
      }
    }
  })

  // Show or hide loading spinner when refresh list is requested by notification filters
  useEffect(() => {
    const refreshListSpinner = notificationsWindowListRefreshingSpinnerRef.current

    if (isRefreshListByFiltersRequested && isGetRefreshListDataByFilterRequested && refreshListSpinner) {
      refreshListSpinner.style.display = 'block'
    }
    if (!isRefreshListByFiltersRequested && !isGetRefreshListDataByFilterRequested && refreshListSpinner) {
      refreshListSpinner.style.display = 'none'
    }
  }, [isRefreshListByFiltersRequested, isGetRefreshListDataByFilterRequested])

  return (
    <>
      <div className="pull-down-to-refresh-list-spinner-container" ref={notificationsWindowListRefreshingSpinnerRef}>
        <div className="pull-down-to-refresh-list-spinner" />
      </div>
      <div className="pull-down-to-refresh-list-expansion-container" ref={notificationsWindowListExpansionContainerRef} />
    </>
  )
}
