//Import components
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from '../store/slices/auth/authActions'
import Loader from '../components/Loader'
import { notificationToast, notificationToastTypes } from '../components/Notifications/NotificationToast'
import LoginPC from '../components/Login/loginPC'
import LoginMobile from '../components/Login/loginMobile'

export default function Login() {
  const { loading, userInfo, error: loginError } = useSelector(state => state.auth)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 718)
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 718)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (userInfo) {
      navigate('/home')
    }
  }, [navigate, userInfo])

  useEffect(() => {
    if (loginError) {
      notificationToast({ notificationType: notificationToastTypes.permissionDenied, message: loginError })
    }
  }, [loginError])

  const handleSubmit = async e => {
    e.preventDefault()
    dispatch(userLogin({ login, password }))
  }

  const handleLoginInput = e => setLogin(e.target.value)
  const handlePasswordInput = e => setPassword(e.target.value)

  return loading ? (
    <Loader />
  ) : isMobile ? (
    <LoginMobile
      handleSubmit={handleSubmit}
      handleLoginInput={handleLoginInput}
      login={login}
      handlePasswordInput={handlePasswordInput}
      password={password}
    />
  ) : (
    <LoginPC
      handleSubmit={handleSubmit}
      handleLoginInput={handleLoginInput}
      login={login}
      handlePasswordInput={handlePasswordInput}
      password={password}
    />
  )
}
