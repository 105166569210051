//Import components
import mapboxgl from 'mapbox-gl'

// Update marker and popup position on the map
export default function updateMarkerPosition(mapPopupVehicleMarker, singleMarkerData, newCoords, vehicleId, map, vehiclesDataRef) {
  const source = map.current.getSource('vehiclesSource')
  if (!source) {
    return
  }

  const currentSourceData = source._data
  const feature = currentSourceData.features.find(feature => feature.properties.vehicle_id === vehicleId)
  if (!feature) {
    return
  }

  const currentCoords = feature.geometry.coordinates
  const currentLngLat = new mapboxgl.LngLat(currentCoords[0], currentCoords[1])
  const newLngLat = new mapboxgl.LngLat(newCoords[0], newCoords[1])

  if (currentCoords[0] && currentCoords[1]) {
    vehiclesDataRef.current[vehicleId].geometry.coordinates = [Number(currentCoords[0]), Number(currentCoords[1])]
  }

  // Check if the new coordinates are the same as the old coordinates
  if (newLngLat.lng === currentLngLat.lng && newLngLat.lat === currentLngLat.lat) {
    return
  }

  const distance = currentLngLat.distanceTo(newLngLat) // Calculate the distance between the current position and the new position
  const duration = Math.max(distance / 100, 3000) // Set the animation duration based on the distance
  const startTime = performance.now() // Calculate the animation start time

  // Animate marker - move smoothly to new position
  let animateMarker = timestamp => {
    let elapsedTime = timestamp - startTime // Calculate the elapsed time since the animation started
    let progress = Math.min(elapsedTime / duration, 1) // Calculate the percentage of the animation that has completed

    // Interpolate the marker position based on the percentage of the animation that has completed
    let lng = currentLngLat.lng + (newLngLat.lng - currentLngLat.lng) * progress
    let lat = currentLngLat.lat + (newLngLat.lat - currentLngLat.lat) * progress
    vehiclesDataRef.current[vehicleId].geometry.coordinates = [lng, lat]
    feature.geometry.coordinates = [lng, lat]
    singleMarkerData.geometry.coordinates = [lng, lat]

    // Update the source data
    source.setData(currentSourceData)
    mapPopupVehicleMarker.setLngLat([lng, lat]) // Set the marker position (for popup)

    // If the animation hasn't completed yet, request another animation frame
    if (progress < 1) requestAnimationFrame(animateMarker)
  }

  // Start the animation
  requestAnimationFrame(animateMarker)
}
