import Refresh from '../../../../assets/images/mapImages/Popup/Icons/Refresh_2.png'
import Pin from '../../../../assets/images/mapImages/Popup/Icons/Pin.png'
import FileDock from '../../../../assets/images/mapImages/Popup/Icons/File_dock_search.png'
import Iot from '../../../../assets/images/mapImages/Popup/Icons/ITO.png'
import Speed from '../../../../assets/images/mapImages/Popup/Icons/Speed.png'
import { choosePopupBatteryImage, choosePopupVehicleCustomStatusImage, choosePopupVehicleTypeImage, timeAgo } from '../../../../helpers/utils'

// Intended to create or update popup when we press on the marker
export default function markerPopup(markerData) {
  const markerProperties = markerData?.properties // single marker properties
  const markerLongitude = markerData?.geometry?.coordinates[0]
  const markerLatitude = markerData?.geometry?.coordinates[1]
  const markersLastUpdateTimestamp = markerProperties.last_updated
  const vehicleLastSignalTimestamp = markerProperties.last_signal_time
  const markerLastRideTimestamp = markerProperties.last_event_time
  const markersLastUpdateDate = new Date(markerProperties.last_updated * 1000).toLocaleString('en-GB') // Last Markers update from Mobility Provider
  const vehicleLastSignalDate = new Date(markerProperties.last_signal_time * 1000).toLocaleString('en-GB')
  const markerLastRideDate = new Date(markerProperties.last_event_time * 1000).toLocaleString('en-GB')
  const markerLastEventType = markerProperties.last_event_types[0]
  const markerCustomStatus = markerProperties.custom_vehicle_status
  const vehicleSpeed = Number(markerProperties.vehicle_speed.toFixed())
  const previousVehicleSpeed = Number(markerProperties.prev_vehicle_speed.toFixed())
  const vehicleId = markerProperties.vehicle_id
  const batteryValue = markerProperties.battery_pct * 100
  let vehicleTypeValue = markerProperties.vehicle_type
  const vehiclePropulsionTypeValue = markerProperties.propulsion_type || 'electric'
  let vehicleStatus, vehicleStatusImg
  let batteryContainer, batteryValueEl, batteryImgValueEl
  let vehicleStatusContainer, vehicleStatusEl, vehicleStatusImgValueEl
  let lastLocationContainer, lastLocationImgValueEl, lastLocationEl, lastLocationLinkEl
  let vehicleTypeContainer, vehicleTypeImgValueEl, vehicleTypeEl
  let lastRideContainer, lastEventTypeImgValueEl, lastEventTypeEl
  let lastEventTypeContainer, lastRideImgValueEl, lastRideEl
  let markersLastUpdateContainer, markersLastUpdateImgValueEl, markersLastUpdateEl
  let vehicleLastSignalContainer, vehicleLastSignalImgValueEl, vehicleLastSignalEl
  let vehicleSpeedContainer, vehicleSpeedImgValueEl, vehicleSpeedEl
  let previousVehicleSpeedContainer, previousVehicleSpeedImgValueEl, previousVehicleSpeedEl
  let lastRideTooltipContainer, lastRideTooltipEl
  let markersLastUpdateTooltipContainer, markersLastUpdateTooltipEl
  let vehicleLastSignalTooltipContainer, vehicleLastSignalTooltipEl

  if (vehicleTypeValue && typeof vehicleTypeValue === 'string') {
    vehicleTypeValue = vehicleTypeValue?.toLowerCase()
  }

  let popupContainer = document.getElementById(`popup-${vehicleId}`)

  if (!popupContainer) {
    popupContainer = document.createElement('div')
    popupContainer.id = `popup-${vehicleId}`
    popupContainer.className = 'popup-container'

    const vehicleIdContainer = document.createElement('div')
    vehicleIdContainer.className = 'vehicle-id-container'
    vehicleIdContainer.innerHTML = `<strong>Vehicle ID:</strong> &nbsp;#${vehicleId}`

    lastRideTooltipContainer = document.createElement('div')
    lastRideTooltipContainer.className = 'last-ride-tooltip-container'
    lastRideTooltipEl = document.createElement('span')
    lastRideTooltipEl.className = 'last-ride-tooltip-value'

    markersLastUpdateTooltipContainer = document.createElement('div')
    markersLastUpdateTooltipContainer.className = 'last-markers-update-tooltip-container'
    markersLastUpdateTooltipEl = document.createElement('span')
    markersLastUpdateTooltipEl.className = 'last-markers-update-tooltip-value'

    vehicleLastSignalTooltipContainer = document.createElement('div')
    vehicleLastSignalTooltipContainer.className = 'vehicle-last-signal-tooltip-container'
    vehicleLastSignalTooltipEl = document.createElement('span')
    vehicleLastSignalTooltipEl.className = 'vehicle-last-signal-tooltip-value'

    vehicleTypeContainer = document.createElement('div')
    vehicleTypeContainer.className = 'vehicle-type-container'
    vehicleTypeImgValueEl = document.createElement('div')
    vehicleTypeImgValueEl.className = 'vehicle-type-img-value'
    vehicleTypeEl = document.createElement('div')
    vehicleTypeEl.className = 'vehicle-type-value'

    vehicleStatusContainer = document.createElement('div')
    vehicleStatusContainer.className = 'vehicle-status-container'
    vehicleStatusImgValueEl = document.createElement('div')
    vehicleStatusImgValueEl.className = 'vehicle-status-img-value'
    vehicleStatusEl = document.createElement('div')
    vehicleStatusEl.className = 'vehicle-status-value'

    lastEventTypeContainer = document.createElement('div')
    lastEventTypeContainer.className = 'last-event-type-container'
    lastEventTypeImgValueEl = document.createElement('div')
    lastEventTypeImgValueEl.className = 'last-event-type-img-value'
    lastEventTypeEl = document.createElement('div')
    lastEventTypeEl.className = 'last-event-type-value'

    lastRideContainer = document.createElement('div')
    lastRideContainer.className = 'last-ride-container'
    lastRideImgValueEl = document.createElement('div')
    lastRideImgValueEl.className = 'last-ride-img-value'
    lastRideEl = document.createElement('div')
    lastRideEl.className = 'last-ride-value'

    batteryContainer = document.createElement('div')
    batteryContainer.className = 'battery-container'
    batteryImgValueEl = document.createElement('div')
    batteryImgValueEl.className = 'battery-img-value'
    batteryValueEl = document.createElement('div')
    batteryValueEl.className = 'battery-value'
    batteryValueEl.textContent = `Battery: ${Math.round(batteryValue)}%`

    lastLocationContainer = document.createElement('div')
    lastLocationContainer.className = 'last-location-container'
    lastLocationImgValueEl = document.createElement('div')
    lastLocationImgValueEl.className = 'last-location-img-value'
    lastLocationEl = document.createElement('div')
    lastLocationEl.className = 'last-location-value'
    lastLocationEl.innerHTML = `<strong>Last location: </strong> &nbsp;`
    lastLocationLinkEl = document.createElement('a')
    lastLocationLinkEl.className = 'last-location-link'

    markersLastUpdateContainer = document.createElement('div')
    markersLastUpdateContainer.className = 'last-markers-update-container'
    markersLastUpdateImgValueEl = document.createElement('div')
    markersLastUpdateImgValueEl.className = 'last-markers-update-img-value'
    markersLastUpdateEl = document.createElement('div')
    markersLastUpdateEl.className = 'last-markers-update-value'

    vehicleLastSignalContainer = document.createElement('div')
    vehicleLastSignalContainer.className = 'vehicle-last-signal-container'
    vehicleLastSignalImgValueEl = document.createElement('div')
    vehicleLastSignalImgValueEl.className = 'vehicle-last-signal-img-value'
    vehicleLastSignalEl = document.createElement('div')
    vehicleLastSignalEl.className = 'vehicle-last-signal-value'

    vehicleSpeedContainer = document.createElement('div')
    vehicleSpeedContainer.className = 'vehicle-speed-container'
    vehicleSpeedImgValueEl = document.createElement('div')
    vehicleSpeedImgValueEl.className = 'vehicle-speed-img-value'
    vehicleSpeedEl = document.createElement('div')
    vehicleSpeedEl.className = 'vehicle-speed-value'

    previousVehicleSpeedContainer = document.createElement('div')
    previousVehicleSpeedContainer.className = 'previous-vehicle-speed-container'
    previousVehicleSpeedImgValueEl = document.createElement('div')
    previousVehicleSpeedImgValueEl.className = 'previous-vehicle-speed-img-value'
    previousVehicleSpeedEl = document.createElement('div')
    previousVehicleSpeedEl.className = 'previous-vehicle-speed-value'

    popupContainer.appendChild(vehicleIdContainer)

    lastRideTooltipContainer.appendChild(lastRideTooltipEl)
    markersLastUpdateTooltipContainer.appendChild(markersLastUpdateTooltipEl)
    vehicleLastSignalTooltipContainer.appendChild(vehicleLastSignalTooltipEl)

    popupContainer.appendChild(vehicleTypeContainer)
    vehicleTypeContainer.appendChild(vehicleTypeImgValueEl)
    vehicleTypeContainer.appendChild(vehicleTypeEl)

    popupContainer.appendChild(vehicleStatusContainer)
    vehicleStatusContainer.appendChild(vehicleStatusImgValueEl)
    vehicleStatusContainer.appendChild(vehicleStatusEl)

    popupContainer.appendChild(lastEventTypeContainer)
    lastEventTypeContainer.appendChild(lastEventTypeImgValueEl)
    lastEventTypeContainer.appendChild(lastEventTypeEl)

    popupContainer.appendChild(lastRideContainer)
    lastRideContainer.appendChild(lastRideImgValueEl)
    lastRideContainer.appendChild(lastRideEl)
    lastRideContainer.appendChild(lastRideTooltipContainer)

    popupContainer.appendChild(batteryContainer)
    batteryContainer.appendChild(batteryImgValueEl)
    batteryContainer.appendChild(batteryValueEl)

    popupContainer.appendChild(lastLocationContainer)
    lastLocationContainer.appendChild(lastLocationImgValueEl)
    lastLocationContainer.appendChild(lastLocationEl)
    lastLocationContainer.appendChild(lastLocationLinkEl)

    popupContainer.appendChild(markersLastUpdateContainer)
    markersLastUpdateContainer.appendChild(markersLastUpdateImgValueEl)
    markersLastUpdateContainer.appendChild(markersLastUpdateEl)
    markersLastUpdateContainer.appendChild(markersLastUpdateTooltipContainer)

    popupContainer.appendChild(vehicleLastSignalContainer)
    vehicleLastSignalContainer.appendChild(vehicleLastSignalImgValueEl)
    vehicleLastSignalContainer.appendChild(vehicleLastSignalEl)
    vehicleLastSignalContainer.appendChild(vehicleLastSignalTooltipContainer)

    popupContainer.appendChild(vehicleSpeedContainer)
    vehicleSpeedContainer.appendChild(vehicleSpeedImgValueEl)
    vehicleSpeedContainer.appendChild(vehicleSpeedEl)

    popupContainer.appendChild(previousVehicleSpeedContainer)
    previousVehicleSpeedContainer.appendChild(previousVehicleSpeedImgValueEl)
    previousVehicleSpeedContainer.appendChild(previousVehicleSpeedEl)
  } else {
    vehicleTypeContainer = popupContainer.querySelector('.vehicle-type-container')
    vehicleTypeImgValueEl = vehicleTypeContainer.querySelector('.vehicle-type-img-value')
    vehicleTypeEl = vehicleTypeContainer.querySelector('.vehicle-type-value')

    vehicleStatusContainer = popupContainer.querySelector('.vehicle-status-container')
    vehicleStatusImgValueEl = vehicleStatusContainer.querySelector('.vehicle-status-img-value')
    vehicleStatusEl = vehicleStatusContainer.querySelector('.vehicle-status-value')

    lastEventTypeContainer = popupContainer.querySelector('.last-event-type-container')
    lastEventTypeImgValueEl = lastEventTypeContainer.querySelector('.last-event-type-img-value')
    lastEventTypeEl = lastEventTypeContainer.querySelector('.last-event-type-value')

    lastRideContainer = popupContainer.querySelector('.last-ride-container')
    lastRideImgValueEl = lastRideContainer.querySelector('.last-ride-img-value')
    lastRideEl = lastRideContainer.querySelector('.last-ride-value')
    lastRideTooltipEl = lastRideContainer.querySelector('.last-ride-tooltip-value')

    batteryContainer = popupContainer.querySelector('.battery-container')
    batteryImgValueEl = batteryContainer.querySelector('.battery-img-value')
    batteryValueEl = batteryContainer.querySelector('.battery-value')

    lastLocationContainer = popupContainer.querySelector('.last-location-container')
    lastLocationImgValueEl = lastLocationContainer.querySelector('.last-location-img-value')
    lastLocationLinkEl = lastLocationContainer.querySelector('.last-location-link')

    markersLastUpdateContainer = popupContainer.querySelector('.last-markers-update-container')
    markersLastUpdateImgValueEl = markersLastUpdateContainer.querySelector('.last-markers-update-img-value')
    markersLastUpdateEl = markersLastUpdateContainer.querySelector('.last-markers-update-value')
    markersLastUpdateTooltipEl = markersLastUpdateContainer.querySelector('.last-markers-update-tooltip-value')

    vehicleLastSignalContainer = popupContainer.querySelector('.vehicle-last-signal-container')
    vehicleLastSignalImgValueEl = vehicleLastSignalContainer.querySelector('.vehicle-last-signal-img-value')
    vehicleLastSignalEl = vehicleLastSignalContainer.querySelector('.vehicle-last-signal-value')
    vehicleLastSignalTooltipEl = vehicleLastSignalContainer.querySelector('.vehicle-last-signal-tooltip-value')

    vehicleSpeedContainer = popupContainer.querySelector('.vehicle-speed-container')
    vehicleSpeedImgValueEl = vehicleSpeedContainer.querySelector('.vehicle-speed-img-value')
    vehicleSpeedEl = vehicleSpeedContainer.querySelector('.vehicle-speed-value')

    previousVehicleSpeedContainer = popupContainer.querySelector('.previous-vehicle-speed-container')
    previousVehicleSpeedImgValueEl = previousVehicleSpeedContainer.querySelector('.previous-vehicle-speed-img-value')
    previousVehicleSpeedEl = previousVehicleSpeedContainer.querySelector('.previous-vehicle-speed-value')
  }

  // Change battery image by battery value
  batteryImgValueEl.style.backgroundImage = choosePopupBatteryImage(batteryValue, vehiclePropulsionTypeValue)

  let vehicleTypeModifiedValue = vehicleTypeValue
  if (vehiclePropulsionTypeValue === 'electric') {
    vehicleTypeModifiedValue = 'e-' + vehicleTypeModifiedValue
    batteryValueEl.innerHTML = `<strong>Battery: </strong> &nbsp;${Math.round(batteryValue)}%`
  } else {
    batteryValueEl.innerHTML = `<strong>Fuel: </strong> &nbsp;${Math.round(batteryValue)}%`
  }

  const chooseCustomStatusImageResult = choosePopupVehicleCustomStatusImage(markerCustomStatus)
  vehicleStatus = chooseCustomStatusImageResult.vehicleStatus
  vehicleStatusImg = chooseCustomStatusImageResult.vehicleStatusImg

  // Change vehicle image by vehicle type
  vehicleTypeImgValueEl.style.backgroundImage = choosePopupVehicleTypeImage(vehicleTypeValue)
  vehicleTypeEl.innerHTML = `<strong>Vehicle type: </strong> &nbsp;${vehicleTypeModifiedValue}`

  vehicleStatusImgValueEl.style.backgroundImage = vehicleStatusImg
  vehicleStatusEl.innerHTML = `<strong>Status: </strong> &nbsp;${vehicleStatus}`

  lastEventTypeImgValueEl.style.backgroundImage = `url(${FileDock})`
  lastEventTypeEl.innerHTML = `<strong>Last event: </strong> &nbsp;${markerLastEventType}`

  lastRideImgValueEl.style.backgroundImage = `url(${Iot})`
  lastRideEl.innerHTML = `<strong>Last ride: </strong> &nbsp;${timeAgo(markerLastRideTimestamp)}`
  lastRideTooltipEl.innerHTML = markerLastRideDate

  lastLocationImgValueEl.style.backgroundImage = `url(${Pin})`
  lastLocationLinkEl.href = `https://www.google.com/maps/place/${markerLatitude},${markerLongitude}`
  lastLocationLinkEl.rel = 'noopener noreferrer'
  lastLocationLinkEl.target = '_blank'
  lastLocationLinkEl.textContent = 'Click Me'

  markersLastUpdateImgValueEl.style.backgroundImage = `url(${Refresh})`
  markersLastUpdateEl.innerHTML = `<strong>Updated: </strong> &nbsp;${timeAgo(markersLastUpdateTimestamp)}`
  markersLastUpdateTooltipEl.innerText = markersLastUpdateDate

  vehicleLastSignalImgValueEl.style.backgroundImage = `url(${Iot})`
  vehicleLastSignalEl.innerHTML = `<strong>Last signal: </strong> &nbsp;${timeAgo(vehicleLastSignalTimestamp)}`
  vehicleLastSignalTooltipEl.innerHTML = vehicleLastSignalDate

  vehicleSpeedImgValueEl.style.backgroundImage = `url(${Speed})`
  vehicleSpeedEl.innerHTML = `<strong>Speed: </strong> &nbsp;${vehicleSpeed} km/h`

  previousVehicleSpeedImgValueEl.style.backgroundImage = `url(${Speed})`
  previousVehicleSpeedEl.innerHTML = `<strong>Prev Speed: </strong> &nbsp;${previousVehicleSpeed} km/h`

  return popupContainer
}
